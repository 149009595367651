import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-in-portrait',
  templateUrl: './view-in-portrait.component.html',
  styleUrls: ['./view-in-portrait.component.scss']
})
export class ViewInPortraitComponent implements OnInit {  
  constructor() {
  }


  ngOnInit(): void {
  }

}
