import * as THREE from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { Modal } from ".";

export interface RotationParams {
  disable?: boolean;  // Set to true to disable rotation for a photosphere
  startX?: number;
  fromX?: number;
  limitX?: Array<number>;
  startY?: number;
  limitY?: Array<number>;
}

export interface ImageData {
  id: string;
  url: string;
  rotation: number;
  x: number;
  y: number;
  z: number;
  mesh?: THREE.Mesh;
  mat?: THREE.MeshBasicMaterial;
  geo?: THREE.PlaneBufferGeometry;
  //created?: boolean;
}

export interface PhotosphereData {
  id: string;
  enabled: boolean;
  opacity: number;
  url: string;
  animate?: boolean;
  duration?: number;
  mesh?: THREE.Object3D;
  texture?: THREE.Texture;
  geometry?: THREE.SphereBufferGeometry;
  material?: THREE.MeshBasicMaterial;
  tween?: any;
  // Minimum window width required to render a photosphere overlay
  // Example: 993 means render only on window widths of 993 pixels wide or wider
  minWindowWidth?: number;
}

export interface PhotosphereUpdate {
  id: string;
  opacity: number;
  animate?: boolean;
  duration?: number;
}

export interface Photosphere2dLayer {
  id: string;
  domId: string;
  xFirst: number;
  yFirst: number;
  xSecond: number;
  ySecond: number;
}

export interface Photosphere {
  id: string;
  name: string;
  type: string;  // "static" (use backgroundImage) or "dynamic" (use mainImage)
  useBackgroundImage?: boolean;  // set to true manually use background image
  url?: string;  // @deprecated using route definitions instead
  showHeader?: boolean;
  portalTitle?: string;
  hotspots: Array<Hotspot>;
  mainImage: string;
  mainScale: number;
  transparencyImage?: string;
  overlayImage?: string;
  clickableImage?: string;
  mainImageBmp?: THREE.Texture;
  transparencyImageBmp?: THREE.Texture;
  overlayImgBmp?: THREE.Texture;
  clickableImgBmp?: THREE.Texture;
  rotationParams: RotationParams;
  images?: Array<ImageData>;
  cycler?: any;
  enableCycler?: boolean;
  zoom?: number;
  showExitButton?: boolean;
  exitButtonTargetPhotosphere?: string;
  showControlsPrompt?: boolean;
  backgroundImage?: string;
  backgroundImgBmp?: THREE.Texture;
  overlayPhotospheres?: Array<PhotosphereData>;
  layers2d?: Array<Photosphere2dLayer>;
  routePath?: string;
}

/**
 * Hotspot data model
 */
export interface Hotspot {

  // Unique id for the hotspot
  id: string;

  // Display type of hotspot marker
  // "default" or "disc"
  markerType: string;

  // Turn off any default styles by removing base class names
  noDefaultStyles?: boolean;

  // Rotate the hotspot marker
  markerRotation?: number;

  // Custom HTML - if markerType is 'custom'
  customHtml?: string;

  // Camera rotation when viewing this hotspot
  rotation?: number;

  // Scale for hotspot
  scale?: number;

  // Simple text label shown along with hotspot icon
  textLabel?: string;

  // X/Y positions
  x: number;
  y: number;

  // Additional CSS class
  cssClass?: string;

  // Can be used to temporary disable (hide) the hotspot
  disabled?: boolean;

  // Click type - functionality to trigger when clicking the hotspot
  // "internal" - internal navigation; main method for showing photospheres and modals
  // "external" - external navigation
  clickType: string;

  // For "external" clickType; the window.open() target
  windowTarget?: string;

  // ID of the related object to show on click
  clickId?: string;

  // URL to open for internal or external navigation clickType
  clickUrl?: string;

  // Hover types
  // "simple"
  // "bubble"
  // "extra"
  hoverType: string;

  // Text to show on hover
  // Can be a single string, or an array of strings as lines
  hoverText?: string;

  // Arbitrary HTML
  // Overrides hoverText when defined
  hoverTextHtml?: string

  // Extra CSS class for hover text
  hoverCssClass?: string;

  // Extra detailed hover content with image for "extra" hoverType
  extraHover?: {
    image?: string;
    image_max_width?: number;
    text?: string;
    text_size?: number;
    position?: string;
  };

  // Any overlay photosphere that should be shown when interacting with this hotspot
  overlayPhotosphere?: {
    overlayId: string;  // id of matching overlayPhotospheres in current photosphere.
    enabled: boolean;  // Is this overlay interaction enabled?
    animation: string;  // They type of animation. Currently only "fade" is supported.
    duration: number;  // The duration of the animation.
    trigger: string;  // The hotspot mouse event that should trigger the animation. Currently only "hover" is supported.
  };

  // Any extra data for use by developers
  extra?: any;

  // Internal: properties for related JavaScript objects
  threeJs?: CSS2DObject;
  elem?: any;
  modal?: Modal;

  // Editor properties
  dragX?: number;
  dragY?: number;
  hsStartX?: number;
  hsStartY?: number;
  selected?: boolean;
  routePath?: string;

}

/**
 * Photosphere route defintion
 */
export interface Route {
  path: string;
  photosphereId: string;  // Photosphere to load
  hotspotId?: string;  // ID of the photosphere hotspot to show
  modalId?: string;  // ID of modal to show
  closePath?: string;
}

/**
 * Photosphere route types
 */
export enum RouteType {
  Photosphere = "photosphere",
  Hotspot = "hotspot"
}

/**
 * A predicate for filtering/searching routes
 */
export interface RoutePredicate {
  prop: string;  // A property in a Route object: "photosphere", "hotspot", "modal", "path"
  value: any;  // The value of the property
}

/**
 * Definition of a photosphere project
 */
export interface Project {
  id: string,
  name: string,
  version: string,
  urls?: ProjectUrls;
}

/**
 * URLs for project data
 */
export interface ProjectUrls {
  cdn?: string;
  direct?: string;
}

/**
 * Data for an entire project
 */
export interface ProjectData {
  project: Project;
  routes: Route[];
  photospheres: Photosphere[];
}
