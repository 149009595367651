
enum ModalType {
    Start = "Start",
    Tutorial = "Tutorial",
    Video = "Video",
    Image = "Image",
    FullscreenVideo = "FullscreenVideo"
}

interface Point {
    x:number, 
    y:number
}

interface Source {
    copy: string;
    url?: string;
}
interface ActionButton {
    label: string;
    action?: string;
}

interface Modal {
    id: string;
    type: ModalType;
    title?: string;
    copy?: string;
    url?: string;
    thumbnail?: string;
    clickPosition?: Point;
    nextModalId?: string;
    sources?: Source[];
    aspectRatio?: any;
    actionButton?: ActionButton;
}

export { ModalType, Modal, Point }
