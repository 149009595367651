import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UiService, IFooterNavState, FooterNavStates } from 'src/app/services/ui.service';
import { PhotosphereService } from 'src/app/services/photosphere.service';
import { SoundService } from 'src/app/services/sound.service';
import { ModalService } from 'src/app/services/modal.service';
import { Modal, Point } from 'src/app/models/modal';
import Modals from 'src/assets/data/modals.json'
import { UtilsService } from 'src/app/services/utils.service';

declare const OneTrust: any;

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {

  @Input() on: boolean = false;

  public drawerOpen: boolean = false;

  public drawerBgEnabled: boolean = false;

  public drawerBgOpen: boolean = false;

  public drawerScroll: boolean = false;

  private drawerTimeout: any;

  public footerNavStates = FooterNavStates;

  public gyroVisible: boolean = false;

  public gyroEnabled: boolean = false;

  constructor(
    public uiSvc: UiService,
    public psSvc: PhotosphereService,
    public modalSvc: ModalService,
    public soundSvc: SoundService,
    public utilsSvc: UtilsService
  ) { }

  ngOnInit(): void {
    this.subscribe();
  }

  @HostListener('document:keyup', ['$event'])
  public keyHandler(e: KeyboardEvent) {
    if (this.drawerOpen) {
      if (e.key === "Escape" || e.key === "Esc") {
        this.uiSvc.drawerOpen$.next(false);
        this.psSvc.interactable.next(true);
      }
    }
  }

  public subscribe() {
    this.uiSvc.drawerOpen$.subscribe((isOpen) => {
      if (this.drawerTimeout) {
        clearTimeout(this.drawerTimeout);
      }
      this.drawerOpen = isOpen;
      if (isOpen) {
        this.drawerBgEnabled = true;
        this.drawerTimeout = setTimeout(() => {
          this.drawerBgOpen = true;
          this.drawerTimeout = setTimeout(() => {
            this.drawerScroll = true;
          }, 350);
        }, 1);
      } else {
        this.drawerScroll = false;
        this.drawerBgOpen = false;
        this.drawerTimeout = setTimeout(() => {
          this.drawerBgEnabled = false;
        }, 350);
      }
    });
    this.psSvc.motionControlsPossible$.subscribe((possible) => {
      this.gyroVisible = possible;
    });
    this.psSvc.motionControls$.subscribe((enabled) => {
      this.gyroEnabled = enabled;
    });
  }

  public drawerBgClick() {
    this.uiSvc.drawerOpen$.next(false);
    this.psSvc.interactable.next(true);
  }

  public speakerClick() {
    const enabled = !this.uiSvc.audioEnabled$.value;
    this.uiSvc.audioEnabled$.next(enabled);
    if (enabled) {
      this.soundSvc.unmuteAll();
      this.soundSvc.play("Ambiance", true);
    }
    else {
      this.soundSvc.muteAll();
    }
  }

  public gyroClick() {
    this.psSvc.motionControlsToggled$.next(null);
  }

  public drawerClick() {
    const enabled = !this.uiSvc.drawerOpen$.value;
    this.uiSvc.drawerOpen$.next(enabled);
    this.psSvc.interactable.next(!enabled);
  }

  public navClick(event: any) {
    this.uiSvc.drawerOpen$.next(false);
    this.psSvc.interactable.next(true);
    let point: Point = { x: event?.clientX ? event.clientX : (window.innerWidth >> 1), y: (event?.clientY) ? event.clientY : (window.innerHeight >> 1) }
    this.utilsSvc.showDotTransition.next(point);
  }

  public cookieSettingsClick($event: any) {
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    if (typeof OneTrust !== 'undefined') {
      OneTrust.ToggleInfoDisplay();
    }
  }

  public showTutorial(event: any) {
    let data = (Modals as Modal[]).find(modal => modal.id === 'tutorial')
    if (data) {
      
      
      // data.clickPosition = { x: event?.clientX ? event.clientX : (window.innerWidth >> 1), y: (event?.clientY) ? event.clientY : (window.innerHeight >> 1) }
      data.clickPosition = { x: -1, y: -1 }
      
      this.modalSvc.modalVisible.next(true);
      this.modalSvc.modalData.next(data);
      // const enabled = !this.uiSvc.drawerOpen$.value;
      // this.uiSvc.drawerOpen$.next(enabled);
      // this.psSvc.interactable.next(!enabled);
    } else {
      console.error("MODAL ID NOT FOUND")
    }
  }

}
