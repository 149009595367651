/**
 * Utility / general services
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Point } from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(null);

  // Listen for scene/photosphere data changes
  public dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // Manually set background image
  public backgroundImageSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  // Drawer menu navigation was clicked?
  public navClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Show the header.. user for initial scene. 
  public showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Show the header.. user for initial scene. 
  public showDotTransition: BehaviorSubject<Point> = new BehaviorSubject<Point>({x:-1, y:-1});

  // Boolean for audio enabled
  public audioEnabled = new BehaviorSubject(false);

  // Object containing all sound html elements
  public audioObjects: any = {};

  // Set global time for main audio to not reset
  public mainAudioTime: number = 0;

  // Global debug flag
  public debug = new BehaviorSubject(false);


  constructor() {
    this.detectIsMobile();
    window.addEventListener("resize", ($event) => {
      this.detectIsMobile();
    });
  }

  detectIsMobile() {
    const isMobile: boolean = window.innerWidth <= 992;
    this.isMobile.next(isMobile);
  }

  // AUDIO
  public initAudio(id: string = 'main-audio'): void {
    let audioEl = document.createElement('audio');
    audioEl.id = id;
    document.body.appendChild(audioEl);
    this.audioObjects[id] = audioEl;
  }

  public setAudioEnabled(enabled: boolean): void {
    this.audioEnabled.next(enabled);

    if (!enabled) {
      this.stopAudio();
    }
  }

  public playAudio(id: string, type: string = 'wav', volume: number = 1, loop: boolean = false, cb: any = undefined, force: boolean = false): void {
    if (this.audioEnabled.value || force) {
      this.audioObjects[id].src = `../../assets/audio/${id}.${type}`;
      if (id === 'main') {
        this.audioObjects[id].currentTime = this.mainAudioTime;
        this.audioObjects[id].ontimeupdate = () => {
          this.mainAudioTime = this.audioObjects[id].currentTime;
        }
      } else {
        this.audioObjects[id].currentTime = 0;
      }
      this.audioObjects[id].volume = volume;
      this.audioObjects[id].loop = loop;
      this.audioObjects[id].load();
      this.audioObjects[id].play();

      if (cb) {
        this.audioObjects[id].onended = (event: any) => {
          cb();
        };
      }
    }
  }

  public setVolume(id: string, volume: number = 1): void {
    this.audioObjects[id].volume = volume;
  }

  private stopAudio(): void {
    for (const audio in this.audioObjects) {
      // this.audioObjects[audio].src = '';
      // if (audio !== 'main') {
      //   this.audioObjects[audio].currentTime = 0;
      // }
      this.audioObjects[audio].pause();
    }
  }

  public iosQuicklookSupported(): boolean {
    const a = document.createElement("a");
    let supported = a.relList.supports("ar");
    // If running in iOS Safari proper, and not within a WKWebView component instance, check for ARQL feature support.
    // Otherwise, if running in a WKWebView instance, check for known ARQL compatible iOS browsers, including:
    // Chrome (CriOS), Edge (EdgiOS), Firefox (FxiOS), Google App (GSA), DuckDuckGo (DuckDuckGo). 
    if (!supported) {
      supported = Boolean(/CriOS\/|EdgiOS\/|FxiOS\/|GSA\/|DuckDuckGo\//.test(navigator.userAgent));
    }
    console.warn("quick look supported? ", supported);
    return supported;
  }

  public initLogs(): void {
    if (localStorage.getItem('houdini-debug') !== null
      && localStorage.getItem('houdini-debug') === 'true'
      || window.location.search.indexOf('debug') > -1) {
      this.debug.next(true);
      return;
    } // END IF
    if (environment.production) {
      const originalconsole = console;
      console.log = function () { };
      console.debug = function () { };
      console.info = function () { };
      console.warn = function () { };
      console.error = function () { };
    }
  }

}