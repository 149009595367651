<div #modalContain id="modalContain" class="modal-container" [ngClass]="{'on': visible}">
    <div #aniDot class="dot" (click)="dotBgClick()"></div>
    <div #theCard class="card" [ngClass]="[visible ? 'on' : '', data?.type ? data?.type : '']">
        
        <div #theInner class="inner" [ngClass]="[visible ? 'on' : '', data?.type ? data?.type : '']">
            <ng-container *ngIf="data?.type === ModalType.Start" [ngClass]="{'on':data?.type === ModalType.Start}">
                <app-start [close]="this.close.bind(this)" ></app-start>
            </ng-container>

            <ng-container *ngIf="data?.type === ModalType.Tutorial">
                <app-tutorial [close]="this.close.bind(this)"></app-tutorial>
            </ng-container>

            <ng-container *ngIf="data && data?.type === ModalType.Video">
                <app-video-modal [close]="this.close.bind(this)" [modal]="this.data"></app-video-modal>
            </ng-container>

            <ng-container *ngIf="data && data?.type === ModalType.Image">
                <app-image-modal [close]="this.close.bind(this)" [modal]="this.data"></app-image-modal>
            </ng-container>

            <ng-container *ngIf="data && data?.type === ModalType.FullscreenVideo" >
                <app-full-video-modal [close]="this.close.bind(this)" [modal]="this.data"></app-full-video-modal>
            </ng-container>
        </div>
    </div>

    <!-- <div class="background-close" (click)="close()" [ngClass]="{'on' : visible}"></div> -->
</div>