import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { UtilsService } from './services/utils.service';
import { HeaderSimpleComponent } from './components/header-simple/header-simple.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { FooterNavComponent } from './components/footer-nav/footer-nav.component';
import { ModalComponent } from './components/modal/modal.component';
import { StartComponent } from './components/start/start.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { PhotosphereComponent } from './components/photosphere/photosphere.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { VideoIntroComponent } from './components/video-intro/video-intro.component';
import { FullVideoModalComponent } from './components/full-video-modal/full-video-modal.component';
import { ViewInPortraitComponent } from './components/view-in-portrait/view-in-portrait.component';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import { LottieModule } from 'ngx-lottie';
import { DotComponent } from './components/dot-transition/dot.component';

export function playerFactory() { 
  return import('lottie-web'); 
} 
import { SourceComponent } from './components/source/source.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderSimpleComponent,
    HeaderNavComponent,
    FooterNavComponent,
    ModalComponent,
    StartComponent,
    TutorialComponent,
    VideoModalComponent,
    ImageModalComponent,
    PhotosphereComponent,
    VideoIntroComponent,
    FullVideoModalComponent,
    ViewInPortraitComponent,
    DotComponent,
    SourceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory})
  ],
  providers: [
    UtilsService,
    { provide: Window, useValue: window },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
  private ref = LottiePlayer;
}
