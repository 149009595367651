<div #dotContain class="dot-container" [ngClass]="{'on': visible}">
    <div #aniDot class="dot">
        
    </div>
    <div #loader class="loader">
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="375" height="58.5" viewBox="0 0 375 58.5">
            <g id="Group_29" data-name="Group 29" transform="translate(0 0)">
                <rect id="Rectangle_1" data-name="Rectangle 32" width="13.596" height="58.5" transform="translate(0 0)"
                    fill="#780a1d" />
                <rect id="Rectangle_2" data-name="Rectangle 33" width="13.596" height="58.5" transform="translate(12.908 0)"
                    fill="#b4151b" />
                <rect id="Rectangle_3" data-name="Rectangle 34" width="13.597" height="58.5" transform="translate(25.815 0)"
                    fill="#8d3519" />
                <rect id="Rectangle_4" data-name="Rectangle 35" width="13.596" height="58.5" transform="translate(38.722 0)"
                    fill="#e03b19" />
                <rect id="Rectangle_5" data-name="Rectangle 36" width="13.596" height="58.5" transform="translate(51.629 0)"
                    fill="#c83f22" />
                <rect id="Rectangle_6" data-name="Rectangle 37" width="13.597" height="58.5" transform="translate(64.536 0)"
                    fill="#97551b" />
                <rect id="Rectangle_7" data-name="Rectangle 38" width="13.596" height="58.5" transform="translate(77.443 0)"
                    fill="#c87419" />
                <rect id="Rectangle_7" data-name="Rectangle 39" width="13.596" height="58.5" transform="translate(90.351 0)"
                    fill="#f0840e" />
                <rect id="Rectangle_8" data-name="Rectangle 40" width="13.596" height="58.5"
                    transform="translate(103.259 0)" fill="#f8b200" />
                <rect id="Rectangle_9" data-name="Rectangle 41" width="13.596" height="58.5"
                    transform="translate(116.166 0)" fill="#fcbd00" />
                <rect id="Rectangle_10" data-name="Rectangle 42" width="13.596" height="58.5"
                    transform="translate(129.073 0)" fill="#ffea43" />
                <rect id="Rectangle_11" data-name="Rectangle 43" width="13.596" height="58.5"
                    transform="translate(141.98 0)" fill="#ffe700" />
                <rect id="Rectangle_12" data-name="Rectangle 44" width="13.596" height="58.5"
                    transform="translate(154.887 0)" fill="#e9de24" />
                <rect id="Rectangle_13" data-name="Rectangle 45" width="13.596" height="58.5"
                    transform="translate(167.795 0)" fill="#6f932d" />
                <rect id="Rectangle_14" data-name="Rectangle 46" width="13.596" height="58.5"
                    transform="translate(180.702 0)" fill="#247732" />
                <rect id="Rectangle_15" data-name="Rectangle 47" width="13.597" height="58.5"
                    transform="translate(193.609 0)" fill="#b3d0ce" />
                <rect id="Rectangle_16" data-name="Rectangle 48" width="13.596" height="58.5"
                    transform="translate(206.517 0)" fill="#003472" />
                <rect id="Rectangle_17" data-name="Rectangle 49" width="13.596" height="58.5"
                    transform="translate(219.424 0)" fill="#391e67" />
                <rect id="Rectangle_18" data-name="Rectangle 50" width="13.596" height="58.5"
                    transform="translate(232.331 0)" fill="#401114" />
                <rect id="Rectangle_19" data-name="Rectangle 51" width="13.596" height="58.5"
                    transform="translate(245.238 0)" fill="#94196d" />
                <rect id="Rectangle_20" data-name="Rectangle 52" width="13.596" height="58.5"
                    transform="translate(258.146 0)" fill="#db005d" />
                <rect id="Rectangle_21" data-name="Rectangle 53" width="13.596" height="58.5"
                    transform="translate(271.053 0)" fill="#8e0e4a" />
                <rect id="Rectangle_22" data-name="Rectangle 54" width="13.596" height="58.5"
                    transform="translate(283.96 0)" fill="#6f0a12" />
                <rect id="Rectangle_23" data-name="Rectangle 55" width="13.596" height="58.5"
                    transform="translate(296.867 0)" fill="#8a5046" />
                <rect id="Rectangle_24" data-name="Rectangle 56" width="13.596" height="58.5"
                    transform="translate(309.775 0)" fill="#b97c69" />
                <rect id="Rectangle_25" data-name="Rectangle 57" width="13.597" height="58.5"
                    transform="translate(322.681 0)" fill="#ffc484" />
                <rect id="Rectangle_26" data-name="Rectangle 58" width="13.596" height="58.5"
                    transform="translate(335.589 0)" fill="#fcd28b" />
                <rect id="Rectangle_27" data-name="Rectangle 59" width="13.596" height="58.5"
                    transform="translate(348.497 0)" fill="#ffedbc" />
                <rect id="Rectangle_28" data-name="Rectangle 60" width="13.596" height="58.5"
                    transform="translate(361.404 0)" fill="#fefefe" />
            </g>
        </svg>
    </div>
</div>