import { Component, OnInit } from '@angular/core';
import { Point } from 'src/app/models/modal';
import { SoundService } from 'src/app/services/sound.service';
import { UiService, IFooterNavState, FooterNavStates } from 'src/app/services/ui.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  public navState?: IFooterNavState;

  public footerNavStates = FooterNavStates;

  constructor(
    public uiSvc: UiService,
    public soundSvc: SoundService,
    public utilsSvc:UtilsService
  ) {
    this.subscribe();
  }

  ngOnInit(): void {
  }

  subscribe(): void {
    this.uiSvc.footerNavState$.subscribe((navState: IFooterNavState|undefined) => {
      this.navState = navState;
    });
  }

  playSound(event:any): void {
    this.soundSvc.play("Button");
    let point: Point = { x: event?.clientX ? event.clientX : (window.innerWidth >> 1), y: (event?.clientY) ? event.clientY : (window.innerHeight >> 1) }
    this.utilsSvc.showDotTransition.next(point);
  }

}