<!-- <div class="cover" [ngClass]="{ on: showCover }">
    <div class="preloader">
        <img *ngIf="coverImage" [src]="coverImage" />
    </div>
</div> -->
  
<div class="cover" [ngClass]="{ on: showCover }">
  <!-- <div id="photocover" class="cover transition "> -->
  <!-- <img src="../../../assets/images/spinner.png" class="transition"> -->
  <div class="loader transition">
    <div class="">
      <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="375" height="58.5" viewBox="0 0 375 58.5">
        <g id="Group_29" data-name="Group 29" transform="translate(0 0)">
          <rect id="Rectangle_1" data-name="Rectangle 32" width="13.596" height="58.5" transform="translate(0 0)"
            fill="#780a1d" />
          <rect id="Rectangle_2" data-name="Rectangle 33" width="13.596" height="58.5" transform="translate(12.908 0)"
            fill="#b4151b" />
          <rect id="Rectangle_3" data-name="Rectangle 34" width="13.597" height="58.5" transform="translate(25.815 0)"
            fill="#8d3519" />
          <rect id="Rectangle_4" data-name="Rectangle 35" width="13.596" height="58.5" transform="translate(38.722 0)"
            fill="#e03b19" />
          <rect id="Rectangle_5" data-name="Rectangle 36" width="13.596" height="58.5" transform="translate(51.629 0)"
            fill="#c83f22" />
          <rect id="Rectangle_6" data-name="Rectangle 37" width="13.597" height="58.5" transform="translate(64.536 0)"
            fill="#97551b" />
          <rect id="Rectangle_7" data-name="Rectangle 38" width="13.596" height="58.5" transform="translate(77.443 0)"
            fill="#c87419" />
          <rect id="Rectangle_7" data-name="Rectangle 39" width="13.596" height="58.5" transform="translate(90.351 0)"
            fill="#f0840e" />
          <rect id="Rectangle_8" data-name="Rectangle 40" width="13.596" height="58.5" transform="translate(103.259 0)"
            fill="#f8b200" />
          <rect id="Rectangle_9" data-name="Rectangle 41" width="13.596" height="58.5" transform="translate(116.166 0)"
            fill="#fcbd00" />
          <rect id="Rectangle_10" data-name="Rectangle 42" width="13.596" height="58.5" transform="translate(129.073 0)"
            fill="#ffea43" />
          <rect id="Rectangle_11" data-name="Rectangle 43" width="13.596" height="58.5" transform="translate(141.98 0)"
            fill="#ffe700" />
          <rect id="Rectangle_12" data-name="Rectangle 44" width="13.596" height="58.5" transform="translate(154.887 0)"
            fill="#e9de24" />
          <rect id="Rectangle_13" data-name="Rectangle 45" width="13.596" height="58.5" transform="translate(167.795 0)"
            fill="#6f932d" />
          <rect id="Rectangle_14" data-name="Rectangle 46" width="13.596" height="58.5" transform="translate(180.702 0)"
            fill="#247732" />
          <rect id="Rectangle_15" data-name="Rectangle 47" width="13.597" height="58.5" transform="translate(193.609 0)"
            fill="#b3d0ce" />
          <rect id="Rectangle_16" data-name="Rectangle 48" width="13.596" height="58.5" transform="translate(206.517 0)"
            fill="#003472" />
          <rect id="Rectangle_17" data-name="Rectangle 49" width="13.596" height="58.5" transform="translate(219.424 0)"
            fill="#391e67" />
          <rect id="Rectangle_18" data-name="Rectangle 50" width="13.596" height="58.5" transform="translate(232.331 0)"
            fill="#401114" />
          <rect id="Rectangle_19" data-name="Rectangle 51" width="13.596" height="58.5" transform="translate(245.238 0)"
            fill="#94196d" />
          <rect id="Rectangle_20" data-name="Rectangle 52" width="13.596" height="58.5" transform="translate(258.146 0)"
            fill="#db005d" />
          <rect id="Rectangle_21" data-name="Rectangle 53" width="13.596" height="58.5" transform="translate(271.053 0)"
            fill="#8e0e4a" />
          <rect id="Rectangle_22" data-name="Rectangle 54" width="13.596" height="58.5" transform="translate(283.96 0)"
            fill="#6f0a12" />
          <rect id="Rectangle_23" data-name="Rectangle 55" width="13.596" height="58.5" transform="translate(296.867 0)"
            fill="#8a5046" />
          <rect id="Rectangle_24" data-name="Rectangle 56" width="13.596" height="58.5" transform="translate(309.775 0)"
            fill="#b97c69" />
          <rect id="Rectangle_25" data-name="Rectangle 57" width="13.597" height="58.5" transform="translate(322.681 0)"
            fill="#ffc484" />
          <rect id="Rectangle_26" data-name="Rectangle 58" width="13.596" height="58.5" transform="translate(335.589 0)"
            fill="#fcd28b" />
          <rect id="Rectangle_27" data-name="Rectangle 59" width="13.596" height="58.5" transform="translate(348.497 0)"
            fill="#ffedbc" />
          <rect id="Rectangle_28" data-name="Rectangle 60" width="13.596" height="58.5" transform="translate(361.404 0)"
            fill="#fefefe" />
        </g>
      </svg>
    </div>
    <div class="shadow shadow-one"></div>
  </div>

</div>
  
<div
  [ngClass]="['photosphere', showPhotosphere ? 'on' : '', backgroundEnabled ? 'backgroundEnabled' : '']"
  [ngStyle]="{ 'background-image': (backgroundEnabled && _backgroundImage ? ('url(' + _backgroundImage + ')') : '') }"
  [id]="(currentPhotosphere ? ('photosphere-' + currentPhotosphere.id) : '')"
>
  <div
    class="bgimg2"
    [ngClass]="[ backgroundEnabled && _backgroundImage2Enabled && _backgroundImage2 ? 'on' : '' ]"
    [ngStyle]="{ 'background-image': (backgroundEnabled && _backgroundImage2 ? ('url(' + _backgroundImage2 + ')') : '') }"
  ></div>
  <div id="container"></div>

  <div
    class="controls-prompt"
    [ngClass]="{ off: hideControls }"
    *ngIf="!disablePhotosphereRotate && !hideprompt"
  >
    <a href="javascript:void(0);" class="close-button" (click)="hideControls = true">&times;</a>
    <h3>Navigation Tips</h3>
    <div class="tip-row">
      <div class="tip-icon">
        <img
          alt="Pointer"
          src="../../assets/images/pointer-icon.png"
          style="transform: translateX(-3px)"
        />
      </div>
      <div class="tip-description">
        <h5>Click and Hold</h5>
        <p>
          Using your mouse or touch pad, click and hold<br />
          anywhere on screen to turn the 3D space.
        </p>
      </div>
    </div>
    <div class="tip-row">
      <div class="tip-icon">
        <img alt="Pointer" src="../../assets/images/hotspot-icon.png" />
      </div>
      <div class="tip-description">
        <h5>Rotate the Gallery</h5>
        <p>Click on any of the walls to access more details.</p>
      </div>
    </div>
  </div>

  <!--
  <div
    *ngIf="motionContorlsDisplay && motionControlsPossible"
    class="controls"
    [ngClass]="{ off: !motionControls }"
    (click)="toggleMotionControls()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <path
        d="M32.97 5.03c6.53 3.1 11.22 9.45 11.93 16.97h3c-1.02-12.32-11.32-22-23.9-22-.45 0-.88.04-1.33.07l7.63 7.63 2.67-2.67zm-12.51-1.54c-1.17-1.17-3.07-1.17-4.24 0l-12.73 12.73c-1.17 1.17-1.17 3.07 0 4.24l24.04 24.04c1.17 1.17 3.07 1.17 4.24 0l12.73-12.73c1.17-1.17 1.17-3.07 0-4.24l-24.04-24.04zm9.2 38.89l-24.05-24.04 12.73-12.73 24.04 24.04-12.72 12.73zm-14.63.59c-6.53-3.1-11.22-9.45-11.93-16.97h-3c1.02 12.32 11.32 22 23.9 22 .45 0 .88-.04 1.33-.07l-7.63-7.63-2.67 2.67z"
      />
    </svg>
  </div>
  -->
</div>
