import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Modal } from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modalFullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalData: BehaviorSubject<Modal|undefined> = new BehaviorSubject<Modal|undefined>(undefined);
  public blockEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
}
