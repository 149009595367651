import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Modal } from 'src/app/models/modal';
import gsap from 'gsap';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  @Input() close: any;
  @Input() modal!: Modal;
  @ViewChild('textbox') _text!: ElementRef;
  @ViewChild('innermodal') _innerModal!: ElementRef;


  textBox?: HTMLElement;
  textBoxH: any = 0;
  innerModal?: HTMLElement;
  modalDims: any = { w: 0, h: 0 };
  fullscreen: boolean = false;
  private resizeDebounce: any;

  constructor(private modalSvc: ModalService) {
  }

  ngOnInit(): void {
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }

  public onWindowResize(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resizeDebounce = setTimeout(this.resize.bind(this), 15);

  }

  private resize(): void {
    // if (!this.fullscreen) {
    //   this.close();
    // } else {
      let h = window.innerHeight;
      let w = window.innerWidth;
      if (this.innerModal) {
        gsap.set(this.innerModal, {height: h, width: w });
      }
    // }
  }

  toggleFullscreen() {
    let full = !this.fullscreen;
    this.fullscreen = !this.fullscreen;
    this.modalSvc.modalFullScreen.next(this.fullscreen);


    if (!this.textBox) {
      this.textBox = this._text.nativeElement as HTMLElement;
      console.log('text-box', this.textBox);
    }
    if (!this.innerModal) {
      this.innerModal = this._innerModal.nativeElement as HTMLElement;
      console.log('MODAL', this.innerModal);
    }
    if (full) {
      this.textBoxH = this.textBox.offsetHeight;
      console.log('this.textBoxH', this.textBoxH);
      let h = window.innerHeight;
      let w = window.innerWidth;
      this.modalDims.h = this.innerModal.offsetHeight;
      this.modalDims.w = this.innerModal.offsetWidth;
      console.log('this.modalDims', this.modalDims);

      gsap.to(this.textBox, { duration: .3, ease: "power2.out", height: 0, opacity: 0, display: "none" });
      gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: h, width: w });
    } else {
      gsap.to(this.textBox, { display: "block", duration: .2, delay: .1, height: this.textBoxH });
      gsap.to(this.textBox, { duration: .3, delay: .6, ease: "linear", opacity: 1 });
      gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: this.modalDims.h, width: this.modalDims.w });
    }
  }

}
