import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-header-simple',
  templateUrl: './header-simple.component.html',
  styleUrls: ['./header-simple.component.scss']
})
export class HeaderSimpleComponent implements OnInit {

  @Input() on: boolean = true;
  public showMe:Boolean = false;

  constructor(private utilSvc:UtilsService, private ref:ChangeDetectorRef ) { 
    
  }

  ngOnInit(): void {
    this.utilSvc.showHeader.subscribe(bshow => {
      console.log('SHOW ME', this.on, this.showMe, bshow)
      if (this.on) {
        this.showMe = bshow;
      }
      if (!this.on) {
        this.showMe = false;
      }
      this.ref.detectChanges();
      console.log('SHOW ME set', this.on, this.showMe, bshow)
    });
  }

}
