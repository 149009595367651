import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import data from '../assets/data/data.json';


let routes: Routes = [
  { path: '', component: AppComponent, pathMatch: 'full' }
];

data.routes.forEach((r: any) => {
  if (r.path !== '/') {
    routes.push({path: r.path.replace('/', ''), component: AppComponent});
  }
});

routes.push({ path: '**', redirectTo: '/' });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
