import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IFooterNavState {
  title: string;
  active: boolean;
  url: string;
  color: string;
  iconUrl: string;
  matchUrls: string[];
}

export const FooterNavStates: IFooterNavState[] = [
  { title: "Meet Christine", active: false, url: "/christine", color: "#5B434D", iconUrl: "/assets/images/farmer.jpg", matchUrls: ["/christine", "/christine-2", "/christine-3", "/christine-4", "/christine-5"] },
  { title: "Explore the Orchard", active: false, url: "/regenerative-farming", color: "#5B434D", iconUrl: "/assets/images/regenerative-farming.jpg", matchUrls: ["/regenerative-farming"] },
  { title: "Explore Soil", active: false, url: "/soil", color: "#5B434D", iconUrl: "/assets/images/soil.png", matchUrls: ["/soil", "/soil-2"] },
  { title: "Explore Honey Bees", active: false, url: "/bees", color: "#F19001", iconUrl: "/assets/images/bees.png", matchUrls: ["/bees", "/bees-2"] },
  { title: "Explore Water", active: false, url: "/water", color: "#95E0E5", iconUrl: "/assets/images/water.png", matchUrls: ["/water", "/water-2"] },
  { title: "Explore Our Partnership", active: false, url: "/our-mission", color: "#BA7C24", iconUrl: "/assets/images/brand-action.png", matchUrls: ["/our-mission", "/our-mission-1", "/our-mission-2"] },
];

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public photosphereEnabled$ = new BehaviorSubject<boolean>(false); 
  public headerType$ = new BehaviorSubject<'simple'|'nav'>('simple');
  public audioEnabled$ = new BehaviorSubject<boolean>(false);
  public drawerOpen$ = new BehaviorSubject<boolean>(false);
  public footerNavState$ = new BehaviorSubject<IFooterNavState|undefined>(undefined);
  public showInstructions$ = new BehaviorSubject<boolean|undefined>(undefined);

  constructor() { }

  public footerNavStateFromUrl(url: string) {
    let navState: IFooterNavState|undefined;
    FooterNavStates.forEach((ns) => {
      ns.active = ns.matchUrls.includes(url);
      if (ns.active) {
        navState = ns;
      }
    });
    this.footerNavState$.next(navState);
  }

}
