import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import gsap from 'gsap';
import { ModalService } from 'src/app/services/modal.service';
import { Modal } from '../../models/modal';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {

  @Input() close: any;
  @Input() modal!: Modal;
  @ViewChild('videoPlayer') _videoPlayerRef!: ElementRef;
  @ViewChild('textbox') _text!: ElementRef;
  @ViewChild('innermodal') _innerModal!: ElementRef;

  videoPlayer?: HTMLVideoElement
  textBox?: HTMLElement;
  textBoxH: any = 0;
  innerModal?: HTMLElement;
  modalDims: any = { w: 0, h: 0 };
  fullscreen: boolean = false;
  private resizeDebounce: any;

  constructor(private modalSvc:ModalService) {
  }

  ngOnInit(): void {
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }

  public onWindowResize(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resizeDebounce = setTimeout(this.resize.bind(this), 15);

  }

  private resize(): void {
    console.log
    // if (!this.fullscreen) {
    //   // this.close();
    // } else {
      let h = window.innerHeight;
      let w = window.innerWidth;
      if (this.innerModal) { 
        gsap.set(this.innerModal, { height: h, width: w });
      }
    // }
  }

  pause() {
    if (!this.videoPlayer) {
      this.videoPlayer = this._videoPlayerRef.nativeElement as HTMLVideoElement
    }

    if (this.videoPlayer && !this.videoPlayer.paused) {
      console.log(this.videoPlayer?.paused)
      this.videoPlayer?.pause();
    }
  }

  play() {
    if (!this.videoPlayer) {
      this.videoPlayer = this._videoPlayerRef.nativeElement as HTMLVideoElement
    }

    if (this.videoPlayer && this.videoPlayer?.paused) {
      this.videoPlayer?.play();
    }
  }

  toggleFullscreen() {
    let full = !this.fullscreen;
    this.fullscreen = !this.fullscreen;
    this.modalSvc.modalFullScreen.next(this.fullscreen);

    if (!this.textBox) {
      this.textBox = this._text.nativeElement as HTMLElement;
      console.log('text-box', this.textBox);
    }
    if (!this.innerModal) {
      this.innerModal = this._innerModal.nativeElement as HTMLElement;
      console.log('MODAL', this.innerModal);
    }
    if (full) {
      this.textBoxH = this.textBox.offsetHeight;
      console.log('this.textBoxH', this.textBoxH);
      let h = window.innerHeight;
      let w = window.innerWidth;
      this.modalDims.h = this.innerModal.offsetHeight;
      this.modalDims.w = this.innerModal.offsetWidth;
      console.log('this.modalDims', this.modalDims);

      gsap.to(this.textBox, { duration: .3, ease: "power2.out", height: 0, opacity: 0, display: "none" });
      gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: h, width: w, onComplete:this.play.bind(this) });
    } else {
      gsap.to(this.textBox, { display: "block", duration: .2, delay: .1, height: this.textBoxH });
      gsap.to(this.textBox, { duration: .3, delay: .6, ease: "linear", opacity: 1 });
      gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: this.modalDims.h, width: this.modalDims.w });
    }
  }
}
