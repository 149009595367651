import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ModalType, Modal } from '../../models/modal';
import Modals from "../../../assets/data/modals.json"
import gsap from 'gsap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  ModalType = ModalType;

  @ViewChild('modalContain') _modal!: ElementRef;
  @ViewChild('aniDot') _dot!: ElementRef;
  @ViewChild('theCard') _theCard!: ElementRef;
  @ViewChild('theInner') _theInner!: ElementRef;
  public visible: boolean = false;
  public data?: Modal = undefined;
  public type: any = '';
  public blockEvent: boolean = false;
  public fullScreen: boolean = false;
  public hidingModal: boolean = false;
  public aniTheDot: boolean = true;
  public dot?: HTMLElement;
  public card?: HTMLElement;
  private resizeDebounce: any;


  constructor(private modalService: ModalService) { }

  ngOnInit(): void {

    this.subscribe();
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }

  public onWindowResize(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resizeDebounce = setTimeout(this.resize.bind(this), 15);

  }

  private resize(): void {
    if (this.data) {
      if (this.data.type !== ModalType.Video && this.data.type !== ModalType.FullscreenVideo && this.data.type !== ModalType.Image) {
        // this.hideModal();
      }


      // let delay = 0;
      // if (this.data?.clickPosition) {
      //   if (this.dot) {
      //     gsap.killTweensOf(this.dot);
      //     gsap.set(this.dot, { scale: 100, backgroundColor: "#00000099" });
      //   }
      // }
      // if (this.card) {
      //   gsap.killTweensOf(this.card);
      //   gsap.set(this.card, { width: 'auto', height: 'auto' });
      // }
    }
  }
  subscribe(): void {
    this.modalService.modalVisible.subscribe(vis => {
      this.visible = vis;
    })

    this.modalService.blockEvent.subscribe(block => {
      this.blockEvent = block;
    })

    this.modalService.modalFullScreen.subscribe(full => {
      this.fullScreen = full;
    })

    this.modalService.modalData.subscribe(data => {
      console.log('data', data);

      this.data = data;
      this.type = (data?.type) ? data.type : '';
      this.modalService.modalFullScreen.next((this.type === ModalType.FullscreenVideo))

      if (!this.dot) {
        this.dot = this._dot?.nativeElement as HTMLElement;
      }
      if (!this.card) {
        this.card = this._theCard?.nativeElement as HTMLElement;
      }

      this.animateModal();
    })
  }

  animateModal(): void {
    console.log('Animate Modal')
    let delay = 0;
    if (this.data?.clickPosition) {
      // pos = { x: data.clickPosition.x, y: data.clickPosition.y }
      if (this.dot) {
        gsap.killTweensOf(this.dot);
        if (this.data.clickPosition.x === -1) {
          this.aniTheDot = false;
          gsap.set(this.dot, { scale: 100, left: window.innerWidth >> 1, top: window.innerHeight >> 1, backgroundColor: "#00000099" })
          gsap.to(this.dot, { duration: 2, opacity: 1, backgroundColor: "#00000099" });
        } else {
          this.aniTheDot = true;
          gsap.set(this.dot, { opacity:1, scale: 0, left: this.data.clickPosition.x, top: this.data.clickPosition.y, backgroundColor: "#ED5A55dd" })
          gsap.to(this.dot, { scale: 100, duration: 2, backgroundColor: "#00000099" });
        }
        delay = .3;
      }
    }
    if (this.card) {
      gsap.killTweensOf(this.card);
      gsap.set(this.card, { width: 2, height: 2 });
      gsap.to(this.card, { width: 'auto', duration: .3, delay: delay });
      gsap.to(this.card, { height: 'auto', duration: .3, delay: delay + .3 });
    }
  }

  hideModal(bAndThen:boolean = false): void {
    console.log("hideModal", bAndThen);
   
    if (this.card) {
      gsap.killTweensOf(this.card);
      gsap.to(this.card, { height: 2, duration: .3, delay: .3 });
      gsap.to(this.card, { width: 2, duration: .3, delay: .6 });
    }
    if (this.dot) {
      gsap.killTweensOf(this.dot);
      if (this.aniTheDot) {
        gsap.to(this.dot, { scale: 0, delay: .5, duration: .7, backgroundColor: "#ED5A5588" });
      } else {
        gsap.to(this.dot, { delay:.5, duration:.7, opacity: 0});
      }
    }
    if (bAndThen) {
      gsap.delayedCall(.9, this.handleNextModal.bind(this));
    } else {
      gsap.delayedCall(1.3, this.hideComplete.bind(this));
    }
  }
  hideComplete(): void {
    this.hidingModal = false;
    this.modalService.modalVisible.next(false);
    this.modalService.modalData.next(undefined);
  }
  dotBgClick(): void {
    if (this.dot) {
      this.close();
    }
  }

  close(): void {
    console.log('this.hidingModal', this.hidingModal);
    
    if (!this.hidingModal) {
      this.hidingModal = true;
      this.hideModal((!this.data?.nextModalId) ? false : true);
    }
  }

  handleNextModal() {
    console.log("handle Next modal");
    this.hidingModal = false;
    let modalId = this.data?.nextModalId;
    if (modalId) {
      setTimeout(() => {
        let data = (Modals as Modal[]).find(modal => modal.id === modalId)
        if (data) {
          this.modalService.modalVisible.next(true);
          this.modalService.modalData.next(data);
        }
      }, 100);
    }
  }
}

