<div class="inner-modal" class="fullscreen">
    <div class="video-inner-full">
        <div class="close-button-full"
         (click)="this.close()">
            <img src="../../../assets/images/close.svg">
        </div>

        <div *ngIf="this.modal.url" class="vid-container-full">
            <video #videoPlayer [src]="this.modal.url" preload playsinline
                [poster]="this.modal.thumbnail"  (click)="this.pause()"></video>
            <div *ngIf="this.videoPlayer.paused" id="playBtn" class="play-button" (click)="this.play()" >
                <img src="../../../assets/images/play.svg">
            </div>
        </div>
    </div>
</div>
