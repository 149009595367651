import { Injectable } from '@angular/core';
import sounds from '../../assets/data/sounds.json'; // sounds.json for PROD
import { PhotosphereService } from './photosphere.service';
import { interval, Subscription, TimeInterval } from 'rxjs';

interface Sound {
  id: string;
  fileName: string;
  volume?: any;
}

const $sounds = sounds.sounds as Sound[];

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private _sounds: { [id: string]: HTMLAudioElement } = {};
  private _subscriptions: { [id: string]: Subscription } = {};
  private _stopping: {[id: string]: {
    interval: NodeJS.Timeout,
    timeout: NodeJS.Timeout
  } | null} = {};
  public defaultTrack:string = "background_audio";
  muted: boolean = false;

  constructor() {}

  public preloadSounds() {
    $sounds.forEach((sound) => {
      this._sounds[sound.id] = new Audio(
        "../../assets/audio/" + sound.fileName
      );
      this._sounds[sound.id].muted = true;
      this._sounds[sound.id].volume = sound.volume || 1;

      document.body.appendChild(this._sounds[sound.id]);
    });
  }

  public play(id: string, loop: boolean = false, speed: number = 1) {
    if (!this._sounds[id]) return;
    if (loop && !this._sounds[id].paused) return;
    console.log("play");
    this.stop(id);
    this._sounds[id].playbackRate = speed;
    this._sounds[id].loop = loop;
    this._sounds[id].play();
  }

  public stop(id: string) {
    if (!this._sounds[id]) return;
    this._sounds[id].pause();
    this._sounds[id].loop = false;
    this._sounds[id].currentTime = 0;

    if(this._subscriptions[id]){
      this._subscriptions[id].unsubscribe();
    }
  }

  public muteAll() {
    this.muted = true;
    $sounds.forEach((sound) => {
      this._sounds[sound.id].muted = true;
    })
  }

  public unmuteAll() {  
    this.muted = false;
    $sounds.forEach((sound) => {
      this._sounds[sound.id].muted = false;
    })
  }

  public playDirectionalSound(audio: any, photosphereSvc: PhotosphereService, loop: boolean = false, speed: number = 1) {
    var id = audio.id;
    var max = audio.max;
    var min = audio.min;
    var targetAngle = audio.rotation;
    if (this._stopping[id] !== null) {
      clearInterval(this._stopping[id]?.interval)
      clearTimeout(this._stopping[id]?.timeout)
      this._stopping[id] = null;
    }

    this.play(id, loop, speed);
    var sub = photosphereSvc.currentRotation.subscribe((rot) => {
      this.play(id, loop, speed);
      this.volumeAdjustDirectional(id, targetAngle, max, min, rot)
    })
    this._subscriptions[id] = sub;
  }

  public volumeAdjustDirectional(id: string, targetAngle: number, max: number, min: number, rot: number) {
    function mod (a:number, n:number) {
      return a - Math.floor(a/n) * n;
    }

    var sound = $sounds.find(obj => id === obj.id);
    var angleBetween = targetAngle - rot
    angleBetween = mod((angleBetween + 180), 360) - 180
    var propVolume = 1 - Math.abs((angleBetween / 180));
    var volume = (max - min) * propVolume + min;
    this._sounds[id].volume = volume;
  }

  public stopDirectionalSound(id: string) {
    if (!this._sounds[id]) return;

    if(this._subscriptions[id]){
      this._subscriptions[id].unsubscribe();
    }
    if (this._stopping[id]) return;
    var startVol = this._sounds[id].volume
    var interval = setInterval(() => {
      this._sounds[id].volume -= (startVol / 200);
    }, 30);
    var timeout = setTimeout(() => {
      this._sounds[id].pause();
      this._sounds[id].loop = false;
      this._sounds[id].currentTime = 0;
      this._sounds[id].volume = 0;
      clearInterval(interval);
    }, 1500);
    this._stopping[id] = {timeout: timeout, interval: interval};
  }
}
