<div class="inner-modal">
    <div class="tut-inner">
        <div class="close-button" (click)="this.close()">
            <img src="../../../assets/images/close.svg">
        </div>
        <p class="tut-title">HOW TO NAVIGATE</p>
        <div class="tut-section">
            <div class="icon-container">
                <img src="../../../assets/images/drag.svg">
                <img src="../../../assets/images/gyro.svg">
            </div>
            <div>
                <p>DRAG THE VIEW LEFT AND RIGHT</p>
                <p>OR TURN ON THE GYRO TO NAVIGATE</p>
            </div>
        </div>
        <div class="tut-section">
            <div class="icon-container">
                
                <!-- <img src="../../../assets/images/flower.svg">
                <img src="../../../assets/images/bird.png"> -->
                <div style="height: 50px;">
                    <lottie-player src="/assets/animations/Flower_Loop.json" background="transparent" speed="1" style="transform: translate(25%, -25%); width: 100px; height: 100px;" autoplay loop></lottie-player>
                </div>
                <div style="height: 50px;">
                    <lottie-player src="/assets/animations/Flower_Bird_Loop.json" background="transparent" speed="1" style="transform: translate(-25%, -25%); width: 100px; height: 100px;" autoplay loop></lottie-player>
                </div>
            </div>
            <p>CLICK ON A HOTSPOT TO EXPLORE</p>
        </div>
        <div class="tut-section">
            <div class="icon-container">
                <div style="height: 50px;">
                    <lottie-player src="/assets/animations/Geo_Loop.json" background="transparent" speed="1" style="transform: translate(0px, -25%); width: 100px; height: 100px;" autoplay loop></lottie-player>
                </div>
            </div>
            <div>
                <p>CLICK ON A LOCATION TO JUMP</p>
                <p>TO THE NEXT AREA</p>
            </div>
        </div>
        <div>
            <p class="tut-help">Having trouble with the experience?</p>
            <p class="tut-help">Try refreshing your browser</p>
        </div>
        <div class="drawer-button-line">
            <a class="button-rectangle" (click)="showGuide()" target="_blank">VIEW EXPERIENCE GUIDE</a>
        </div>
    </div>
</div>
