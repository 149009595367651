import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalType, Point } from '../../models/modal';
import gsap from 'gsap';
import { UtilsService } from 'src/app/services/utils.service';
import { PhotosphereService } from 'src/app/services/photosphere.service';

@Component({
  selector: 'app-dot',
  templateUrl: './dot.component.html',
  styleUrls: ['./dot.component.scss']
})
export class DotComponent implements OnInit {


  @ViewChild('aniDot') _dot!: ElementRef;
  @ViewChild('loader') _loader!: ElementRef;
  public visible: boolean = false;
  public dot?: HTMLElement;
  public loader?: HTMLElement;
  private resizeDebounce: any;


  constructor(private utilsSvc: UtilsService, private photoSvc:PhotosphereService) { }

  ngOnInit(): void {
    this.subscribe();
  }

  public onWindowResize(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resizeDebounce = setTimeout(this.resize.bind(this), 15);

  }

  private resize(): void {
  }

  subscribe(): void {

    this.utilsSvc.showDotTransition.subscribe((clickPt: Point) => {
      if (!this.dot) {
        this.dot = this._dot?.nativeElement as HTMLElement;
      }
      if (!this.loader) {
        this.loader = this._loader?.nativeElement as HTMLElement;
      }
      
      
      if (clickPt.x !== -1) {
        this.visible = true;
        this.animateDot(clickPt);
      }
    });

    // this.photoSvc.currentPhotosphere.subscribe((data:any)=> {
    //   console.log("DOT  - PHOTOSPHERE", data);
      // if (this.visible) {
      //   this.hideDot();
      // }
    // });
  }

  animateDot(clickPt: Point): void {
    console.log('Animate DOT')
    let delay = 0;
    if (clickPt) {
      // pos = { x: data.clickPosition.x, y: data.clickPosition.y }
      if (this.dot) {
        gsap.killTweensOf(this.dot);
        // gsap.set(this.dot, { scale: 0, opacity:1, left: clickPt.x, top: clickPt.y, backgroundColor: "#ED5A55dd" })
        gsap.set(this.dot, { scale: 0, opacity: 1, left: clickPt.x, top: clickPt.y, backgroundColor: "#52bbc0" })
        gsap.to(this.dot, { scale: 100, duration: .7, ease: "power1.out", backgroundColor: "#52bbc0", onComplete:this.hideDot.bind(this) });
        delay = .3;
      }
      if (this.loader) {
        console.log("SHOW THE LOADER");
        gsap.killTweensOf(this.loader);
        gsap.to(this.loader, { delay: .1, duration: 0.3, ease: "power1.out", opacity:1 });
      }
    }
  }

  hideDot(): void {
    console.log("HIDE DOT");
    
    if (this.dot) {
      gsap.killTweensOf(this.dot);
      gsap.to(this.dot, { opacity: 0, ease: "power1.in", duration: .5});
    }
    if (this.loader) {
      gsap.killTweensOf(this.loader);
      gsap.to(this.loader, { duration: 0.4, ease: "power1.out", opacity: 0 });
    }

    gsap.delayedCall(.5, this.hideComplete.bind(this));

  }
  hideComplete(): void {
    console.log("HIDE DOT COMPELTE");
    this.visible = false;

  }

}

