<!-- <div class="sound-cont">
  <a href="#" class="sound-btn" [ngClass]="{'on': audioEnabled, 'off': showInstructions}" (click)="$event.preventDefault(); toggleAudio()">
    <div class="icon on">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 258.1 174.9" xml:space="preserve"><path fill="none" d="M0 0h258.1v174.9H0z"/><path d="M166.2 65H92.1c-17.1 0-30.9 13.8-31 30.8s13.8 30.9 30.8 31 30.9-13.8 31-30.8c0-6.7-2.2-13.3-6.2-18.6h24.8c-10.2 13.6-7.5 33 6.2 43.2 13.6 10.2 33 7.5 43.2-6.2 10.2-13.6 7.5-33-6.2-43.2-5.3-4-11.8-6.2-18.5-6.2zm-74.1 49.4c-10.2 0-18.5-8.3-18.5-18.5s8.3-18.5 18.5-18.5 18.5 8.3 18.5 18.5-8.3 18.5-18.5 18.5zm74.1 0c-10.2 0-18.5-8.3-18.5-18.5s8.3-18.5 18.5-18.5 18.5 8.3 18.5 18.5c.1 10.2-8.2 18.5-18.5 18.5zm49.4-100.9h-173C32.4 13.6 24.1 21.8 24 32v107.1c.1 10.2 8.3 18.5 18.5 18.5h173c10.2-.1 18.5-8.3 18.5-18.5v-107c.1-10.2-8.2-18.5-18.4-18.6zm-35.5 12.4-8.2 8.2H86.4l-8.3-8.2h102zm41.7 113.3c0 3.4-2.8 6.2-6.2 6.2h-173c-3.4 0-6.2-2.8-6.2-6.2V32.1c0-3.4 2.8-6.2 6.2-6.2h18l18.7 18.6c1.2 1.2 2.7 1.8 4.4 1.8h90.7c1.6 0 3.2-.7 4.4-1.8l18.7-18.7h18.1c3.4 0 6.2 2.8 6.2 6.2v107.2z" fill="#deace5"/></svg>
    </div>
    <div class="icon off">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 258.24 175.01"><g><path d="M254.63 160.32 232.8 146a18.53 18.53 0 0 0 1.36-6.84V32.06a18.67 18.67 0 0 0-18.53-18.54h-173a18.54 18.54 0 0 0-8.43 2.09L12.39 1.31a8 8 0 0 0-8.78 13.38l20.86 13.69a18.6 18.6 0 0 0-.4 3.68v107.09a18.69 18.69 0 0 0 18.54 18.54h173a18.61 18.61 0 0 0 4.8-.68l25.42 16.68a8 8 0 0 0 8.78-13.37Zm-74.5-134.45-8.24 8.23H86.36l-8.24-8.23Zm-119.48 0 18.67 18.67a6.15 6.15 0 0 0 4.37 1.82h90.73a6.2 6.2 0 0 0 4.37-1.82l18.67-18.67h18a6.18 6.18 0 0 1 6.19 6.19l.11 106.72L188.69 117a30.86 30.86 0 0 0-22.5-52h-56.76L49.8 25.87Zm67.6 51.49h13.29a31.2 31.2 0 0 0-3.64 6.34ZM110.59 95.9a18.56 18.56 0 0 1-18.54 18.53 18.54 18.54 0 1 1 18.54-18.53Zm37.84-5.29a18.53 18.53 0 1 1 29.66 19.47ZM42.61 145.34a6.18 6.18 0 0 1-6.18-6.19V36.23L82.54 66.5a30.91 30.91 0 1 0 40.18 26.37l13.18 8.65a30.84 30.84 0 0 0 37.2 24.42l29.55 19.4Z" fill="#deace5"/></g></svg>
    </div>
  </a>
</div> -->

<div class="container">
  <div class="header">
    <app-header-simple
      [on]="this.uiSvc.headerType$.value === 'simple'"
    ></app-header-simple>
    <app-header-nav
      [on]="this.uiSvc.headerType$.value === 'nav'"
    ></app-header-nav>
  </div>  
  <app-photosphere
    *ngIf="this.uiSvc.photosphereEnabled$.value"
    coverImage="../assets/images/spinner-white.png"
    loadingImage="../assets/images/spinner-white.png"
    [disablePhotosphereRotate]="false"
    (hotspotClicked)="onHotspotClick($event)"
  ></app-photosphere>
  <app-video-intro
    *ngIf="showIntroVideo"
    (onVideoEnded)="onVideoEnded()"
  ></app-video-intro>
  <app-footer-nav></app-footer-nav>
</div>
<app-modal></app-modal>
<app-dot></app-dot>
<app-view-in-portrait *ngIf="this.isLandscape() && !fullscreen"></app-view-in-portrait>
