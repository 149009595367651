<div #innermodal class="inner-modal" [ngClass]="{'fullscreen': this.fullscreen}">
    <div [ngClass]="{'fullscreen': this.fullscreen}" class="close-button" (click)="this.close()">
        <img src="../../../assets/images/close.svg">
    </div>
    <div #textbox class="text-box">
        <div class="image-inner">
            <p class="title">{{this.modal.title}}</p>
            <p class="copy">{{this.modal.copy}}</p>
            <ng-container *ngIf="this.modal.sources">
                <app-source *ngFor="let source of this.modal.sources" [copy]="source.copy" [url]="source.url"></app-source>
            </ng-container>
        </div>
    </div>
    
    <div class="image-box" [ngClass]="{'fullscreen': this.fullscreen}">
        <!-- <div *ngIf="this.modal.url" class="img-container" > -->
            <div *ngIf="this.modal.url" class="img-container"
                [ngStyle]="{'aspect-ratio': (this.modal?.aspectRatio ? this.modal?.aspectRatio : 16/9)}">
            <img #image class="image" [src]="this.modal.url" [ngClass]="{'fullscreen': this.fullscreen}">
            <div (click)="this.toggleFullscreen()" id="fullscreenBtn" class="fullscreen-button">
                <img src="../../../assets/images/fullscreen.svg">
            </div>
        </div>
    </div>
</div>
