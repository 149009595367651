import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-full-video-modal',
  templateUrl: './full-video-modal.component.html',
  styleUrls: ['./full-video-modal.component.scss']
})
export class FullVideoModalComponent implements OnInit {

  @Input() close: any;
  @Input() modal!: Modal;
  @ViewChild('videoPlayer') _videoPlayerRef!: ElementRef;

  videoPlayer?: HTMLVideoElement

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.play();  
    }, 0);
  }

  pause() {
    if(!this.videoPlayer) {
      this.videoPlayer = this._videoPlayerRef.nativeElement as HTMLVideoElement
    }

    if(this.videoPlayer && !this.videoPlayer.paused) {
      console.log(this.videoPlayer?.paused)
      this.videoPlayer?.pause();
    }
  }

  play() {
    if(!this.videoPlayer) {
      this.videoPlayer = this._videoPlayerRef.nativeElement as HTMLVideoElement
    }

    if(this.videoPlayer) {
      console.log(this.videoPlayer?.paused)
      this.videoPlayer?.play();
    }
  }

}
