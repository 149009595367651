<div #innermodal class="inner-modal" [ngClass]="{'fullscreen': this.fullscreen}">
    <div [ngClass]="{'fullscreen': this.fullscreen}" class="close-button" (click)="this.close()">
        <img src="../../../assets/images/close.svg">
    </div>
    <div #textbox class="text-box">
        <div class="video-inner">
            <p class="title" [innerHtml]="this.modal.title"></p>
            <p class="copy" [innerHtml]="this.modal.copy"></p>
            <ng-container *ngIf="this.modal.sources">
                <app-source *ngFor="let source of this.modal.sources" [copy]="source.copy" [url]="source.url"></app-source>
            </ng-container>
        </div>
    </div>
    <div class="video-box" [ngClass]="{'fullscreen': this.fullscreen}">
        <div *ngIf="this.modal.url" class="vid-container"
        [ngStyle]="{'aspect-ratio': (this.modal?.aspectRatio ? this.modal?.aspectRatio : 16/9)}">
            <video #videoPlayer [src]="this.modal.url" preload playsinline [poster]="this.modal.thumbnail"
                [ngClass]="{'fullscreen': this.fullscreen}" (click)="this.pause()"></video>
            <div *ngIf="this.videoPlayer.paused" id="playBtn" class="play-button" (click)="this.play()">
                <img src="../../../assets/images/play.svg">
            </div>
            <div (click)="this.toggleFullscreen()" id="fullscreenBtn" class="fullscreen-button">
                <img src="../../../assets/images/fullscreen.svg">
            </div>
        </div>
    </div>
    <div class="action-row" *ngIf="this.modal?.actionButton && !this.fullscreen">
        <div class="button-rectangle action-button" *ngIf="this.modal?.actionButton" (click)="this.close()">
            <p>{{this.modal?.actionButton?.label}}</p>
        </div>
    </div>
</div>
