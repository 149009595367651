<div class="video-intro">
    <video
        #video
        [muted]="true"
        [ngClass]="{ on: showVideo }"
        playsinline
        (ended)="videoEnded($event)"
        (timeupdate)="videoTimeUpdate($event, video)"
        (durationchange)="videoDurationChange($event)"
        (loadedmetadata)="videoLoadedMetaData($event, video); video.playbackRate = .9"
        oncanplay="this.muted=true"
        onloadedmetadata="this.muted=true"
        width="1280"
        height="720"
    >
        <source src="/assets/videos/RoadIntroShotv2.mp4" type="video/mp4">
    </video>
    <div #videoBg class="video-bg" >
    <!-- <div #videoBg class="video-bg" [ngStyle]="{ 'transition-duration': animationDuration + 'ms' }"> -->
    </div>
    <!-- <button class="enter button-transparent" (click)="exitClick()">
        Enter the experience
    </button> -->
</div>

<div #cover class="cover">
    <div #lottie class="lottie">
        <ng-lottie  height="auto" 
        [options]="aniOptions" 
        (animationCreated)="animationCreated($event)"
        (complete)="complete($event)"
        (enterFrame)="enterFrame($event)"
        (loopComplete)="loopComplete($event)">
    </ng-lottie>
        <!-- <lottie-player #lottieFlowAni src="/assets/animations/Flower_Intro.json" background="transparent" speed="1"
            style="transform: translate(-25%, -25%); width: 100px; height: 100px;" autoplay></lottie-player> -->
    </div>
</div>