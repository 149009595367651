import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as THREE from "three";
import { CSS2DRenderer, CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { DeviceOrientationControls } from "three/examples/jsm/controls/DeviceOrientationControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { Euler, TextureLoader } from "three";
import { TweenMax, Power2 } from "gsap";
import { PhotosphereService } from 'src/app/services/photosphere.service';
import { Hotspot, ImageData, Photosphere, PhotosphereData, Photosphere2dLayer } from "../../models";
import { Router } from '@angular/router';
import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader.js";
import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader.js";
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-photosphere',
  templateUrl: "./photosphere.component.html",
  styleUrls: [ "./photosphere.component.scss" ]
})
export class PhotosphereComponent implements OnInit, OnChanges, OnDestroy {

  /***************************************************************************
   * Declare properties used during dependency injection
   ***************************************************************************/
  private photosphereSvc: PhotosphereService;
  private router: Router;
  private soundSvc: SoundService;

  /***************************************************************************
   * Photosphere data properties
   ***************************************************************************/

  /**
   * Manage photosphere data source loading / observables
   */

  get fetchDataObservable(): Observable<any> {
    return this.photosphereSvc._fetchDataObservable;
  }

  @Input()
  set fetchDataObservable(observable: Observable<any>) {
    //if (this.photosphereSvc._fetchDataObservable) return;
    this.photosphereSvc._fetchDataObservable = observable;
    //if (observable) this.subscribeOnDataChanges();
  }

  // @todo - Do we still need this variable?
  private fetchingData: boolean = false;

  /**
   * Background image management
   */
  public backgroundEnabled: boolean = false;
  public _backgroundImage: string = "";
  public _backgroundImage2: string = ""; // second background image to transition to
  public _backgroundImage2Enabled: boolean = false;
  
  @Input()
  set backgroundImageSubject(subject: BehaviorSubject<any>) {
    if (subject) {
      subject.subscribe((url) => {
        if (url) {
          this._backgroundImage2Enabled = true;
          this._backgroundImage2 = url;
        } else {
          this._backgroundImage2Enabled = false;
        }
      });
    }
  }

  /***************************************************************************
   * @editor
   ***************************************************************************/

  // Local model variable controlling if photosphere is editable
  public editable: boolean = false;

  // Hotspot that is currently receiving a mousedown event
  public mousedownHotspot: Hotspot|null = null;

  // Drag sensitivity / distance
  @Input()
  public dragDistance: number = 10;

  /***************************************************************************
   * Photosphere events
   ***************************************************************************/

  // Hotspot click
  @Output() hotspotClicked = new EventEmitter<any>();

  // Hotspot mouseenter
  @Output() hotspotEnter = new EventEmitter<any>();

  // Hotspot mouseleave
  @Output() hotspotLeave = new EventEmitter<any>();

  // Hotspot mousedown
  @Output() hotspotDown = new EventEmitter<any>();

  // Hotspot mouseup
  @Output() hotspotUp = new EventEmitter<any>();


  /***************************************************************************
   * Photosphere configuration
   ***************************************************************************/

  // Field of view for photosphere viewer
  @Input() fov: number = 75;

  // Disable photosphere rotation
  @Input() disablePhotosphereRotate: boolean = false;

  // Reduce width by the specified amount
  @Input() widthModifier: number = 0;

  // Reduce height by the specified amount
  @Input() heightModifier: number = 0;

  // Cover image
  @Input() coverImage: string = "../assets/images/cover.png";

  // Spinner loading image
  @Input() loadingImage: string = "../assets/images/spinner.png";

  // Is this component visible? 
  @Input() visible: boolean = true;


  /***************************************************************************
   * Photosphere rendering properties
   ***************************************************************************/
  public mainMaterial?: THREE.MeshBasicMaterial;
  public clickMaterial?: THREE.MeshBasicMaterial;

  public hotspots: any[] = [];

  public updatedSphere = false;

  // Momentum
  private scroll_timeout: any;
  private linear_tween: any;
  private momentum_tween: any;
  private momentumDiff = 0;
  private momentumX = 0;
  private acceleration: any;
  private startTime: any;
  private speed: any;
  private config = {
    momentum: true,
    linear_duration: 0.3, // seconds
    momentum_duration: 1, // seconds
    linear_distance_factor: 4,
    momentum_distance_factor: 10,
    scroll_timeout: 20, // milliseconds
  };

  public currentPhotosphere: Photosphere|null = null;

  public numSpheres: number = 0;
  public numLoadedSpheres: number = 0;

  public showTransition = true;
  public showCover = false;
  public coverEl: HTMLElement|null = null;
  public showPhotosphere = false;
  public modalVisible: boolean = false;
  public photoSphereInteractive: boolean = false;
  public motionControls = false;
  public motionContorlsDisplay = true;
  public motionControlsPossible = false;
  public motionControlsPermitted = false;

  public loadedSphere = false;
  public loadedTransition = false;
  public initialized = false;

  public camera?: THREE.PerspectiveCamera;
  public controls: any;
  public scene?: THREE.Scene;
  public pickingScene?: THREE.Scene;
  public renderer?: THREE.WebGLRenderer;
  public composer?: EffectComposer;
  public hotspotRenderer?: CSS2DRenderer;
  public cameraTarget?: THREE.Vector3;
  //public plane: CSS2DObject;
  public olMaterial?: THREE.MeshBasicMaterial;
  public ovMaterial?: THREE.MeshBasicMaterial;
  public pickHelper?: GPUPickHelper;
  public mainTexture?: THREE.Texture;
  public aImageData: Array<ImageData> = [];
  public isUserInteracting = false;
  public onMouseDownMouseX = 0;
  public onMouseDownMouseY = 0;
  public lonVal = 135;
  public rotation_tween: any;
  public fov_tween: any;
  public onMouseDownLon = 0;
  public latVal = 0;
  public onMouseDownLat = 0;
  public phi = 0;
  public theta = 0;
  public hideControls = false;  // Determines if the photosphere controls are shown
  public targetRotation = 0;
  public hideprompt = true;
  public currentOverlays: PhotosphereData[] = [];

  private clock = new THREE.Clock();
  private target?: THREE.WebGLRenderTarget;
  private motionPass: any;
  private previousMatrixWorldInverse = new THREE.Matrix4();
  private previousProjectionMatrix = new THREE.Matrix4();
  private previousCameraPosition = new THREE.Vector3();
  private tmpMatrix = new THREE.Matrix4();

  private resizeDebounce:any;

  //public udpateUrl = false;
  get lon(): number {
    return this.lonVal;
  }
  set lon(x) {
    if (this.currentPhotosphere && this.currentPhotosphere.rotationParams?.limitX) {
      this.lonVal = Math.min(
        Math.max(x, this.currentPhotosphere.rotationParams?.limitX[0]),
        this.currentPhotosphere.rotationParams?.limitX[1]
      );
    } else {
      this.lonVal = x;
    }
  }
  get lat(): number {
    return this.latVal;
  }
  set lat(y) {
    if (this.currentPhotosphere && this.currentPhotosphere.rotationParams?.limitY) {
      this.latVal = Math.min(
        Math.max(y, this.currentPhotosphere.rotationParams?.limitY[0]),
        this.currentPhotosphere.rotationParams?.limitY[1]
      );
    } else {
      this.latVal = y;
    }
  }

  /***************************************************************************
   * Constructor and main definition
   ***************************************************************************/

  /**
   * constructor
   */
  constructor(
    photosphereSvc: PhotosphereService,
    router: Router,
    soundSvc: SoundService
  ) {
    this.photosphereSvc = photosphereSvc;
    this.router = router;
    this.soundSvc = soundSvc;
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // Reference to cover2 DOM element
    this.coverEl = document.getElementById("photocover");
    // Listen for project data, including photosphere data
    this.subscribeOnDataChanges();
  }

  /**
   * ngOnChanges()
   */
  ngOnChanges(): void {
    this.showCover = true;
    this.showPhotosphere = false;
    this.loadedSphere = false;
    this.loadedTransition = false;
  }

  /**
   * ngOnDestroy()
   * - Remove mouse listeners
   */
  ngOnDestroy(): void {
    document.removeEventListener("mousedown", this.onPointerStart);
    document.removeEventListener("mousemove", this.onPointerMove);
    document.removeEventListener("mouseup", this.onPointerUp);
    document.removeEventListener("wheel", this.onDocumentMouseWheel);
    document.removeEventListener("touchstart", this.onPointerStart);
    document.removeEventListener("touchmove", this.onPointerMove);
    document.removeEventListener("touchend", this.onPointerUp);
    window.removeEventListener("resize", this.onWindowResize);
  }

  /**
   * Called when _fetchDataObservable is set.
   * Subscribes to the observable and setups photospheres
   */
   private subscribeOnDataChanges(): void {
    // Initial data fetch (HTTP)
    this.fetchingData = true;
    this.photosphereSvc._fetchDataObservable
      .pipe(tap(() => (this.fetchingData = true)))
      .subscribe(
        (data: any) => {
          this.fetchingData = false;
          if (data) {
            this.initFromData(data);
          }
        },
        (error) => {
          this.fetchingData = false;
          console.error(error, "is the photosphere data fetch error");
        }
      );
    // Subsequent data fetch (importing JSON)
    this.photosphereSvc.fetchDataTrigger.subscribe((data) => {
      if (data) {
        this.initFromData(data);
      }
    });
  }

  /**
   * Initialize all photospheres from the given data structure
   * @param data
   */
  public initFromData(data: any) {
    // set the data in the photospheres service
    this.photosphereSvc.setData(data);
    // preload all sphere data
    this.preloadAllSpheres();
    // look for photosphere matching current route
    this.photosphereSvc.setPhotosphereFromRoute();
  }

  /**
   * Listen for changes to photosphere; like rotation, photosphere update, etc.
   */
  subscribe(): void {

    this.photosphereSvc.rotation.subscribe((data) => {
      this.targetRotation = data;
    });

    this.photosphereSvc.manualRotation.subscribe((data) => {
      // Manually set rotation
      this.rotateTo(data);
      // Notify components about new rotation
      this.photosphereSvc.rotation.next(data);
    });

    this.photosphereSvc.manualZoom.subscribe((data) => {
      if (data > 0) {
        this.zoomTo(data);
      }
    });

    this.photosphereSvc.manualLat.subscribe((data) => {
      this.latitudeTo(data);
    });

    this.photosphereSvc.rotateToHotspot.subscribe((data) => {
      if (data && data?.rotation) {
        this.rotateTo(data.rotation);
        this.photosphereSvc.rotation.next(data.rotation);
      }
    });

    this.photosphereSvc.interactable.subscribe((data) => {
      this.photoSphereInteractive = data;
    });

    // Detect update to the current photosphere
    this.photosphereSvc.currentPhotosphere.subscribe((data) => {

      // Turn off any background image swapper
      this._backgroundImage2Enabled = false;

      // Set the current photosphere
      this.currentPhotosphere = data;

      if (this.currentPhotosphere && !this.updatedSphere) {
        // updatedSphere is set in this block; controls if we need to initialize THREE.js viewer
        this.init();
        this.animate();
        this.updatedSphere = true;
      }

      if (this.currentPhotosphere) {
        // Load new photosphere textures after a zoom animation
        this.loadNewPhotosphere();
        // Auto-hide prompt when loading "center" photosphere
        if (this.currentPhotosphere.id === "center" && this.hideprompt) {
          setTimeout(() => {
            this.hideprompt = false;
          }, 500);
        }
        // Check for additional 2D layers
        this.layers2dCheck(this.currentPhotosphere);
      }

    });

    // Setting main texture by photosphere id
    this.photosphereSvc.mainTextureId.subscribe((data) => {
      this.setMainTextureById(data);
    });

    // Redraw hotspots?
    this.photosphereSvc.redrawHotspots.subscribe((data) => {
      if (data) {
        this.initHotspots();
      }
    });

    // Process any updates to overlay photospheres
    this.photosphereSvc.overlayPhotosphereUpdate.subscribe((data) => {
      if (data && this.currentPhotosphere && this.currentPhotosphere.overlayPhotospheres) {
        const psData = this.currentPhotosphere.overlayPhotospheres.find((item) => item.id === data.id);
        if (psData) {
          this.overlayPhotosphereOpacity(psData, data.opacity, data.animate, data.duration);
        }
      }
    });

    // Listen for an update to a photosphere image
    this.photosphereSvc.updatePhotosphereImage.subscribe((data) => {
      if (data?.url && data?.photosphereId && data?.updateProp) {
        this.updatePhotosphereImage(data.url, data.photosphereId, data.updateProp);
      }
    });

    // Toggle motion controls
    this.photosphereSvc.motionControlsToggled$.subscribe((data) => {
      this.toggleMotionControls();
    });

  }

  /**
   * Detect if we're using a static background image
   * @returns
   */
  public useBackgroundImage(photosphere: Photosphere|null = null): boolean {
    if (photosphere) {
      return !!(photosphere.type === "static" || photosphere.useBackgroundImage);
    } else {
      return !!(this.currentPhotosphere && (this.currentPhotosphere.type === "static" || this.currentPhotosphere.useBackgroundImage));
    }
  }

  /**
   * Draws textures for an updated photosphere, after a zoom/fov animation
   */
  public loadNewPhotosphere(): void {
    // Use a background image?
    this.backgroundEnabled = this.useBackgroundImage();
    this._backgroundImage = this.currentPhotosphere && this.currentPhotosphere.backgroundImage ? this.currentPhotosphere.backgroundImage : "";
    const zoom = (this.currentPhotosphere && this.currentPhotosphere.zoom) ? this.currentPhotosphere.zoom : 1;
    // Wait a tick for any existing fov animation to complete
    setTimeout(() => {
      if (this.fov_tween) {
        this.fov_tween.kill();
      }
      // Wait a tick to start fov animation
      setTimeout(() => {
        this.loadedTransition = true;
        if (!this.motionControls && this.targetRotation && this.composer && this.camera) {
          // this.showTransition = true;
          this.composer.addPass(this.motionPass);
          this.fov_tween = TweenMax.to(this.camera, 0.4, {
            fov: 65,
            zoom: zoom,
            onUpdate: this.onCameraUpdate.bind(this),
            onComplete: this.zoomComplete.bind(this),
            ease: "power1.inOut"
          });
        } else if (this.camera) {
          this.camera.zoom = zoom;
          this.showTransition = false;
          this.zoomComplete();
        }
      }, 1);
    }, 1);
  }

  /**
   * Callback for `loadNewPhotosphere()` when zoom animation has completed
   */
  public zoomComplete(): void {
    if (this.camera) {
      this.camera.fov = 75;
      // Clear any previous overlay photospheres after zooming away from previous photosphere
      this.overlayPhotospheresClear();
      // Initialize new photosphere overlays
      this.overlayPhotospheresInit();
      // Setup additiona threeJS textures
      this.setTextures();
      this.setImageTextures();
      if (this.loadedSphere && this.composer) {
        this.sphereTextureLoaded();
        this.composer.removePass(this.motionPass);
        this.onCameraUpdate();
      }
    }
  }

  /**
   * Preload photospheres into THREE.js textures
   */
  public preloadAllSpheres(): void {
    if (!this.photosphereSvc.photospheres) {
      return;
    }
    this.photosphereSvc.photospheres.forEach((p) => {
      this.preloadSphere(p);
    });
  }

  /**
   * Preload a photosphere
   * @param p
   */
  public preloadSphere(p: Photosphere) {
    if (p.mainImage) {
      this.numSpheres++;
      p.mainImageBmp = new THREE.TextureLoader().load(
        p.mainImage,
        this.sphereLoaded.bind(this) // On Load Event
      );
      if (p.transparencyImage) {
        this.numSpheres++;
        p.transparencyImageBmp = new THREE.TextureLoader().load(
          p.transparencyImage,
          this.sphereLoaded.bind(this) // On Load Event
        );
      }
      if (p.overlayImage) {
        this.numSpheres++;
        p.overlayImgBmp = new THREE.TextureLoader().load(
          p.overlayImage,
          this.sphereLoaded.bind(this) // On Load Event
        );
      }
      if (p.clickableImage) {
        this.numSpheres++;
        p.clickableImgBmp = new TextureLoader().load(
          p.clickableImage,
          this.sphereLoaded.bind(this)
        );
      }
      if (p.overlayPhotospheres) {
        for (let i in p.overlayPhotospheres) {
          if (this.overlayPhotosphereEnabled(p.overlayPhotospheres[i])) {
            this.numSpheres++;
            p.overlayPhotospheres[i].texture = new TextureLoader().load(
              p.overlayPhotospheres[i].url,
              this.sphereLoaded.bind(this)
            );
          }
        }
      }
    }
    if (p.backgroundImage && this.useBackgroundImage(p)) {
      p.backgroundImgBmp = new TextureLoader().load(p.backgroundImage);
    }
  }

  /**
   * Callback for THREE.js photosphere image loading.
   * Once all photospheres have been loaded, will trigger the photosphereLoaded subject.
   * It's only possible to setCurrentPhotosphere once photosphereLoaded is true.
   */
   public sphereLoaded(): void {
    this.numLoadedSpheres++;
    if (this.numLoadedSpheres >= this.numSpheres) {
      this.photosphereSvc.photosphereLoaded.next(true);
      this.photosphereSvc.interactable.next(true);
      this.loadedSphere = true;
      this.showTransition = true;
      this.showCover = false;
      this.subscribe();
    }
  }

  /**
   * Called after a sphere texture has loaded
   * - Initializes hotspots/labels
   */
  public sphereTextureLoaded(): void {
    // The main sphere texture is loaded.
    this.loadedSphere = true;

    // Did the texture finish before our little loading delay?
    if (this.loadedTransition) {
      this.showCover = false;
      this.showPhotosphere = true;
      this.initHotspots();

      // Scene enter animation.
      if (this.currentPhotosphere && !this.motionControls && this.targetRotation) {
        const destin = this.targetRotation;
        const fromVal = this.currentPhotosphere.rotationParams.fromX
          ? this.currentPhotosphere.rotationParams.fromX
          : destin - 10;
        const animTime = 2;
        const delay = 0.5;
        if (this.coverEl) {
          TweenMax.to(this.coverEl.style, 0.6, {
            delay: 0.1,
            opacity: 0,
            onComplete: this.coverOffComplete.bind(this),
          });
        }
        this.rotation_tween = TweenMax.fromTo(
          this,
          animTime,
          { lon: fromVal },
          { lon: destin, delay: delay, ease: "expo.out" }
        );
        // Also animate zoom
        let zoom = this.currentPhotosphere.zoom
          ? this.currentPhotosphere.zoom
          : 1;
        //this.camera.zoom = zoom;
        if (this.camera) {
          this.camera.fov = 75;
        }
        this.onCameraUpdate();
        // this.fov_tween = TweenMax.fromTo(
        //   this.camera,
        //   animTime + delay,
        //   { fov: 55 },
        //   {
        //     fov: 75,
        //     zoom: zoom,
        //     onUpdate: this.onCameraUpdate.bind(this),
        //   }
        // );
      }
    }
  }

  /**
   * Manually set photosphere zoom
   * @param zoom
   */
  public zoomTo(zoom: number) {
    if (this.fov_tween) {
      this.fov_tween.kill();
    }
    if (this.camera) {
    this.fov_tween = TweenMax.fromTo(
        this.camera,
        0.5,
        { fov: 75 },
        {
          fov: 75,
          zoom: zoom,
          onUpdate: this.onCameraUpdate.bind(this),
        }
      );
    }
  }

  /**
   * Rotate to given X position
   * @param rotation
   */
  public rotateTo(rotation: number) {
    if (this.rotation_tween) {
      this.rotation_tween.kill();
    }
    if (this.currentPhotosphere) {
      const fromVal = this.targetRotation ? this.targetRotation : (this.currentPhotosphere.rotationParams.fromX ? this.currentPhotosphere.rotationParams.fromX : 180);
      if (fromVal !== rotation) {
        this.rotation_tween = TweenMax.fromTo(
          this,
          0.75,
          { lon: fromVal },
          { lon: rotation, delay: 0, ease: "expo.out" }
        );
      }
    }
  }

  /**
   * Latitude animate to give Y position
   * @param lat
   */
  public latitudeTo(lat: number) {
    if (this.rotation_tween) {
      this.rotation_tween.kill();
    }
    if (this.currentPhotosphere) {
      const fromVal = this.lat ? Math.round(this.lat) : (this.currentPhotosphere.rotationParams.startY ? this.currentPhotosphere.rotationParams.startY : 5);
      if (fromVal !== lat) {
        this.rotation_tween = TweenMax.fromTo(
          this,
          0.75,
          { lat: fromVal },
          { lat: lat, delay: 0, ease: "expo.out" }
        );
      }
    }
  }

  /**
   * Callback for TweenMax
   * Triggered after any cover element has been loaded
   */
  public coverOffComplete(): void {
    this.showTransition = false;
  }

  /**
   * Callback
   * Triggers a sphere update THREE.js camera
   */
  public onCameraUpdate(): void {
    if (this.camera) {
      this.camera.updateProjectionMatrix();
    }
  }

  /***************************************************************************
   * THREE.js photosphere viewer
   ***************************************************************************/

  public firstInit: boolean = true;

  /**
   * Initialize THREE.js viewer
   * Called whenever the currentPhotosphere is changed
   */
  public init(): void {
    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      this.getPhotosphereWidth() / this.getPhotosphereHeight(),
      0.1,
      1100
    );

    // Set initial zoom, home page only, for the first photosphere only.
    if (
      this.currentPhotosphere &&
      this.currentPhotosphere.zoom &&
      this.firstInit
    ) {
      this.camera.zoom = this.currentPhotosphere.zoom;
      this.camera.updateProjectionMatrix();
    } else {
      //this.camera.zoom = 1;
    }

    this.firstInit = false;

    this.cameraTarget = new THREE.Vector3(0, 0, 0);

    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0x000000);
    this.pickingScene = new THREE.Scene();

    this.resetAssets();

    let container: HTMLElement|null;
    container = document.getElementById("container");

    this.renderer = new THREE.WebGLRenderer();
    // this.renderer.setClearColor( 0xffffff );
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
    this.pickHelper = new GPUPickHelper(this.renderer);
    container && container.appendChild(this.renderer.domElement);

    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));

    // // @tmp @blur
    // // Not sure why this blur was here?
    // let hblur = new ShaderPass(HorizontalBlurShader);
    // this.composer.addPass(hblur);

    // // @tmp @blur
    // // Not sure why this blur was here?
    // let vblur = new ShaderPass(VerticalBlurShader);
    // // set this shader pass to render to screen so we can see the effects
    // vblur.renderToScreen = true;
    // this.composer.addPass(vblur);

    // define a render target with a depthbuffer
    this.target = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);
    this.target.depthBuffer = true;
    this.target.depthTexture = new THREE.DepthTexture(window.innerWidth, window.innerHeight);

    // add a motion blur pass
    this.motionPass = new ShaderPass(motionBlurShader);
    this.motionPass.renderToScreen = false;
    //this.composer.addPass(this.motionPass);

    this.hotspotRenderer = new CSS2DRenderer();
    this.hotspotRenderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
    this.hotspotRenderer.domElement.style.position = "absolute";
    this.hotspotRenderer.domElement.style.top = "0px";
    this.hotspotRenderer.domElement.id = "hotspot_renderer";
    //this.hotspotRenderer.domElement.classList.add("disableOnBackground");
    container && container.appendChild(this.hotspotRenderer.domElement);

    document.addEventListener(
      "mousedown",
      this.onPointerStart.bind(this),
      false
    );
    document.addEventListener(
      "mousemove",
      this.onPointerMove.bind(this),
      false
    );
    document.addEventListener("mouseup", this.onPointerUp.bind(this), false);
    document.addEventListener(
      "touchstart",
      this.onPointerStart.bind(this),
      false
    );
    document.addEventListener(
      "touchmove",
      this.onPointerMove.bind(this),
      false
    );
    document.addEventListener("touchend", this.onPointerUp.bind(this), false);
    window.addEventListener("resize", this.onWindowResize.bind(this), false);

    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof (DeviceMotionEvent as any).requestPermission === "function"
    ) {
      this.motionControlsPossible = true;
      this.photosphereSvc.motionControlsPossible$.next(true);
      this.promptMotionControls();
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      this.motionControlsPossible = true;
      this.photosphereSvc.motionControlsPossible$.next(true);
      this.permitMotionControls();
    }

  }

  /**
   * Start animation for the frame
   * Bind animate() to requestAnimationFrame
   */
  public animate(): void {
    requestAnimationFrame(this.animate.bind(this));
    this.update();
  }

  /**
   * Update photosphere camera, hotspot positions, etc.
   * Calls the renderer to update canvas
   */
  public update(): void {

    const delta = this.clock.getDelta();
    if (this.showPhotosphere) {
      if (this.motionControls) {
        this.controls.update();

        const cameraVector = new THREE.Vector3();
        if (this.camera) {
          this.camera.getWorldDirection(cameraVector);
        }
        cameraVector.y = 0;
        const zeroVector = new THREE.Vector3(1, 0, 0);
        let angle = (zeroVector.angleTo(cameraVector) * 180) / Math.PI;
        if (cameraVector.z < 0) {
          angle = 360 - angle;
        }
        this.photosphereSvc.currentRotation.next(angle);
      } else {
        this.lat = Math.max(-85, Math.min(85, this.lat));
        this.phi = THREE.MathUtils.degToRad(90 - this.lat);
        this.theta = THREE.MathUtils.degToRad(this.lon);

        if (this.camera && this.cameraTarget) {
          this.cameraTarget.x = 550 * Math.sin(this.phi) * Math.cos(this.theta);
          this.cameraTarget.y = 550 * Math.cos(this.phi);
          this.cameraTarget.z = 550 * Math.sin(this.phi) * Math.sin(this.theta);
          this.camera.lookAt(this.cameraTarget);
          const cameraVector = new THREE.Vector3(
            this.cameraTarget.x,
            0,
            this.cameraTarget.z
          );
          const zeroVector = new THREE.Vector3(1, 0, 0);
          let angle = (zeroVector.angleTo(cameraVector) * 180) / Math.PI;
          if (cameraVector.z < 0) {
            angle = 360 - angle;
          }
          this.photosphereSvc.currentRotation.next(angle);
          this.photosphereSvc.currentLat.next(this.lat);
        }
      }

      // 
      // Render the photosphere
      //
      if (!this.photosphereSvc.blurred.value) {
        if (this.renderer && this.scene && this.camera) {
          this.renderer.render(this.scene, this.camera);
        }
      } else {
        if (this.composer && this.renderer && this.target && this.camera && this.scene) {
          this.composer.render();
          // render scene and depthbuffer to the render target
          this.renderer.render(this.scene, this.camera);
          //this.renderer.render(this.scene, this.camera, this.target)
          // update motion blur shader uniforms
          this.motionPass.material.uniforms.tColor.value = this.target.texture
          this.motionPass.material.uniforms.tDepth.value = this.target.depthTexture
          this.motionPass.material.uniforms.velocityFactor.value = .004
          this.motionPass.material.uniforms.delta.value = delta
          // tricky part to compute the clip-to-world and world-to-clip matrices
          this.motionPass.material.uniforms.clipToWorldMatrix.value
            .getInverse(this.camera.matrixWorldInverse).multiply(this.tmpMatrix.getInverse(this.camera.projectionMatrix))
          this.motionPass.material.uniforms.previousWorldToClipMatrix.value
            .copy(this.previousProjectionMatrix.multiply(this.previousMatrixWorldInverse))
          this.motionPass.material.uniforms.cameraMove.value.copy(this.camera.position).sub(this.previousCameraPosition)

          // render the postprocessing passes
          this.composer.render(delta)

          // save some values for the next render pass
          this.previousMatrixWorldInverse.copy(this.camera.matrixWorldInverse)
          this.previousProjectionMatrix.copy(this.camera.projectionMatrix)
          this.previousCameraPosition.copy(this.camera.position)
        }
      }

      //
      // Render hotspots
      //
      if (this.hotspotRenderer && this.scene && this.camera) {
        this.hotspotRenderer.render(this.scene, this.camera);
      }

    }
  }

  /**
   * Get photosphere width, less any width modifier
   * @returns
   */
  public getPhotosphereWidth(): number {
    return window.innerWidth - this.widthModifier;
  }

  /**
   * Get photosphere height, less any height modifier
   * @returns
   */
  public getPhotosphereHeight(): number {
    return window.innerHeight - this.heightModifier;
  }

  /**
   * Reset photosphere viewer state
   */
  public resetAssets(): void {

    if (!this.currentPhotosphere || !this.currentPhotosphere?.mainImage) {
      return;
    }

    let mesh: THREE.Object3D;
    let olMesh: THREE.Object3D;
    let ovMesh: THREE.Object3D;
    let clickMesh: THREE.Object3D;

    const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);

    this.mainTexture = this.currentPhotosphere.mainImageBmp;
    this.sphereTextureLoaded();
    this.mainMaterial = new THREE.MeshBasicMaterial({ map: this.mainTexture, transparent: true });
    this.mainMaterial.needsUpdate = true;
    mesh = new THREE.Mesh(geometry, this.mainMaterial);

    if (this.currentPhotosphere.transparencyImage) {
      const olGeometry = new THREE.SphereBufferGeometry(400, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      olGeometry.scale(-1, 1, 1);

      const olTexture = this.currentPhotosphere.transparencyImageBmp;
      this.olMaterial = new THREE.MeshBasicMaterial({
        map: olTexture,
        transparent: true,
      });
      this.olMaterial.needsUpdate = true;

      olMesh = new THREE.Mesh(olGeometry, this.olMaterial);
      this.scene?.add(olMesh);
    }

    if (this.currentPhotosphere.overlayImage && this.scene) {
      const ovGeometry = new THREE.SphereBufferGeometry(15, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      ovGeometry.scale(-1, 1, 1);

      const ovTexture = this.currentPhotosphere.overlayImgBmp;
      this.ovMaterial = new THREE.MeshBasicMaterial({
        map: ovTexture,
        transparent: true,
      });
      this.ovMaterial.needsUpdate = true;

      ovMesh = new THREE.Mesh(ovGeometry, this.ovMaterial);
      this.scene.add(ovMesh);
    }

    if (this.currentPhotosphere.clickableImage && this.pickingScene) {
      const clickGeometry = new THREE.SphereBufferGeometry(300, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      clickGeometry.scale(-1, 1, 1);

      const clickTexture = this.currentPhotosphere.clickableImgBmp;
      this.clickMaterial = new THREE.MeshBasicMaterial({ map: clickTexture });
      this.clickMaterial.needsUpdate = true;

      clickMesh = new THREE.Mesh(clickGeometry, this.clickMaterial);
      this.pickingScene.add(clickMesh);
    }

    this.scene?.add(mesh);
    this.hideControls = false;
    this.setImageTextures();
  }

  /**
   * Create textures for any images configured in the photosphere's "images" array
   */
  setImageTextures(): void {

    if (!this.currentPhotosphere) {
      return;
    }

    this.aImageData.forEach((imgData) => {
      if (imgData.mesh) {
        imgData.mesh.visible = false;
      }
    });

    if (this.currentPhotosphere.images) {
      this.currentPhotosphere?.images.forEach((img) => {
        let bNew = true;
        const x = img.x;
        const y = img.y;
        const z = img.z;
        const rot = img.rotation;
        const imgOld = this.aImageData.find((i) => i.id === img.id);
        if (imgOld) {
          bNew = false;
          img = imgOld;
          if (img.mesh) {
            img.mesh.visible = true;
          }
        }

        if (bNew) {
          img.mat = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(img.url),
            side: THREE.DoubleSide,
            transparent: true,
          });
          img.geo = new THREE.PlaneBufferGeometry(16.76, 10.34, 4, 4);
          img.mesh = new THREE.Mesh(img.geo, img.mat);
          this.scene?.add(img.mesh);
          this.aImageData.push(img);
        }

        const pos = this.getPositionFromEquirectangularSpace(x, y);
        if (img.mesh) {
          img.mesh.position.set(pos.x, pos.y, pos.z);
          img.mesh.rotation.set(0, 0, 0);
          img.mesh.scale.set(z, z, 1);
          img.mesh.rotateOnAxis(
            new THREE.Vector3(0, 1, 0),
            (rot * Math.PI) / 180
          );
        }
        // img.mesh.lookAt(new THREE.Vector3(0,0,0));
        if (img.mesh) {
          img.mesh.updateWorldMatrix(true, false);
        }
      });
    }
  }

  /**
   * Update photosphere viewer textures from current photosphere
   */
  public setTextures() {
    if (!this.currentPhotosphere) {
      return;
    }

    //this.setMainTexture(this.currentPhotosphere.mainImageBmp);
    this.setMainTextureById(this.currentPhotosphere.id);

    if (this.currentPhotosphere.transparencyImage && this.olMaterial && this.currentPhotosphere.transparencyImageBmp) {
      this.olMaterial.map = this.currentPhotosphere.transparencyImageBmp;
      this.olMaterial.needsUpdate = true;
    }

    if (this.currentPhotosphere.overlayImage && this.ovMaterial && this.currentPhotosphere.overlayImgBmp) {
      this.ovMaterial.map = this.currentPhotosphere.overlayImgBmp;
      this.ovMaterial.needsUpdate = true;
    }

    if (this.currentPhotosphere.clickableImage && this.currentPhotosphere.clickableImgBmp && this.clickMaterial) {
      this.clickMaterial.map = this.currentPhotosphere.clickableImgBmp;
      this.clickMaterial.needsUpdate = true;
    }

    if (this.currentPhotosphere.overlayPhotospheres) {
      for (let op of this.currentPhotosphere.overlayPhotospheres) {
        if (op.enabled) {
          this.overlayPhotosphereOpacity(op, op.opacity);
        }
      }
    }

    this.lon = this.currentPhotosphere.rotationParams.startX ?? 0;
    this.lat = this.currentPhotosphere.rotationParams.startY ?? 0;
    this.loadedSphere = true;
  }

  /**
   * Set the main texture
   * @param texture
   */
  public setMainTexture(texture: THREE.Texture) {
    this.mainTexture = texture;
    if (this.mainMaterial) {
      this.mainMaterial.map = this.mainTexture;
      // this.mainMaterial.transparent = true;
      // this.mainMaterial.opacity = 1;
      this.mainMaterial.needsUpdate = true;
    }
    this.mainTexture.needsUpdate = true;
  }

  /**
   * Set the main texture by photosphere id
   * @param photosphereId
   */
  public setMainTextureById(photosphereId: string) {
    if (photosphereId) {
      if (this.photosphereSvc.photospheres) {
        const photosphereData: any = this.photosphereSvc.photospheres.find(
          (x) => x.id === photosphereId
        );
        if (photosphereData && photosphereData.mainImageBmp) {
          this.setMainTexture(photosphereData.mainImageBmp);
        }
      }
    }
  }

  /**
   * Update a photosphere image
   * @param photosphereId
   * @param updateProp
   * @param url
   * @returns
   */
  public updatePhotosphereImage(url: string, photosphereId: any, updateProp: string) {
    const photosphere = this.photosphereSvc.lookupPhotosphere(photosphereId);
    if (!photosphere) {
      return;
    }
    if (updateProp === "mainImage") {
      this.showCover = true;
      photosphere.mainImage = url;
      photosphere.mainImageBmp = new THREE.TextureLoader().load(
        photosphere.mainImage,
        () => {
          this.showCover = false;
          if (this.currentPhotosphere && this.currentPhotosphere.id === photosphereId) {
            // Redraw current photosphere when it matches the updated texture photosphere
            this.resetAssets();
          }
        }
      );
    }
  }



  /***************************************************************************
   * Overlay photosphere interactions and animations
   ***************************************************************************/

  /**
   * Check if a hotspot has a photosphere overlay
   * @param hotspot
   */
  public overlayPhotosphereHotspot(hotspot: Hotspot, eventName: string) {
    // overlayPhotosphere
    if (hotspot?.overlayPhotosphere && hotspot.overlayPhotosphere.enabled) {
      if (hotspot.overlayPhotosphere.trigger === "hover") {
        this.photosphereSvc.updateOverlayPhotosphere({
          id: hotspot.overlayPhotosphere.overlayId,
          opacity: eventName === "mouseenter" ? 1 : 0,
          animate: true,
          duration: hotspot.overlayPhotosphere.duration
        });
      } else {
        throw new Error(`Unsupported overlayPhotosphere.trigger '${hotspot.overlayPhotosphere.trigger}'`);
      }
    }
  }

  /**
   * Initialize photosphere overlays
   */
  public overlayPhotospheresInit() {
    if (this.currentPhotosphere && this.currentPhotosphere.overlayPhotospheres) {
      for (let i in this.currentPhotosphere.overlayPhotospheres) {
        this.overlayPhotosphereSetup(this.currentPhotosphere.overlayPhotospheres[i]);
      }
      this.currentOverlays = this.currentPhotosphere.overlayPhotospheres;
    } else {
      this.currentOverlays = [];
    }
  }

  /**
   * Clear current photosphere overlays
   */
  public overlayPhotospheresClear() {
    if (this.currentOverlays && this.currentOverlays.length && this.scene) {
      for (let psData of this.currentOverlays) {
        if (psData.mesh) {
          this.scene.remove(psData.mesh);
        }
      }
    }
  }

  /**
   * Setup an overlay photosphere
   * @param psData
   */
  public overlayPhotosphereSetup(psData: PhotosphereData) {
    if (this.overlayPhotosphereEnabled(psData)) {
      psData.geometry = new THREE.SphereBufferGeometry(15, 60, 40);
      psData.geometry.scale(-1, 1, 1);
      psData.material = new THREE.MeshBasicMaterial({
        map: psData.texture,
        transparent: true,
        opacity: psData.opacity
      });
      psData.material.needsUpdate = true;
      psData.mesh = new THREE.Mesh(psData.geometry, psData.material);
      this.scene?.add(psData.mesh);
    }
  }

  /**
   * Set the opacity of a photosphere overlay
   * @param psData
   * @param opacity
   */
  public overlayPhotosphereOpacity(psData: PhotosphereData, opacity: number, animate: any = false, duration: any = 0.6) {
    if (this.overlayPhotosphereEnabled(psData) && psData.material && psData.texture) {
      psData.material.map = psData.texture;
      psData.material.transparent = true;
      let targetOpacity = Math.min(1, Math.max(0, opacity));
      if (animate) {
        if (psData.tween) {
          psData.tween.kill();
        }
        psData.tween = TweenMax.to(psData.material, duration, {
          opacity: targetOpacity
        });
      } else {
        psData.material.opacity = targetOpacity;
      }
    }
  }

  /**
   * Determine if a photosphere overlay is enabled / should be rendered
   * @param psData
   * @returns
   */
  public overlayPhotosphereEnabled(psData: PhotosphereData): boolean {
    if (!psData.enabled) {
      return false;
    }
    if (psData.minWindowWidth) {
      return window.innerWidth >= psData.minWindowWidth;
    }
    return true;
  }


  /***************************************************************************
   * Additional 2D layers
   ***************************************************************************/

  // First draw of 2d layers?
  public layers2dFirst: boolean = true;

  // References to 2d layer object
  public layers2d: CSS2DObject[] = [];

  /**
   * Check for 2D layers to add to scene from a give photosphere
   * @param ps
   */
  public layers2dCheck(ps: Photosphere|null) {
    //this.layers2dReset();
    if (ps && ps.layers2d) {
      for (let layer2d of ps.layers2d) {
        this.layer2dAdd(layer2d);
      }
    }
    this.layers2dFirst = false;
  }

  /**
   * Add a 2D layer to the current scene
   * @todo @tmp Refactor away xSecond and ySecond? Believe the issue was caused by a different-sized photosphere being loaded and re-shaping the threejs world scene
   * @param layer2d
   */
  public layer2dAdd(layer2d: Photosphere2dLayer) {
    const elem: HTMLElement|null = document.getElementById(layer2d.domId);
    if (elem) {
      const css2dObject = new CSS2DObject(elem);
      //const mainScale = this.currentPhotosphere ? this.currentPhotosphere.mainScale : 1;
      const forward = this.getPositionFromEquirectangularSpace(
        this.layers2dFirst ? layer2d.xFirst : layer2d.xSecond,
        this.layers2dFirst ? layer2d.yFirst : layer2d.ySecond,
      );
      css2dObject.position.x = forward.x;
      css2dObject.position.y = forward.y;
      css2dObject.position.z = forward.z;
      this.scene?.add(css2dObject);
      this.layers2d.push(css2dObject);
    }
  }

  /**
   * Remove all 2D layers
   */
  public layers2dReset() {
    if (this.layers2d && this.layers2d.length) {
      for (let css2dObject of this.layers2d) {
        this.scene?.remove(css2dObject);
      }
      this.layers2d = [];
    }
  }

  /***************************************************************************
   * User interactions with photosphere viewer
   * - Mouse interactions
   * - Motion controls
   * - Misc. browser events
   ***************************************************************************/

  /**
   * Update camera and renderers on window size change
   */
  public onWindowResize(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resizeDebounce = setTimeout(this.resize.bind(this), 50);
    
  }

  private resize():void {
    if (this.camera && this.renderer && this.hotspotRenderer) {
      this.camera.aspect =
        this.getPhotosphereWidth() / this.getPhotosphereHeight();
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.getPhotosphereWidth(),
        this.getPhotosphereHeight()
      );
      this.hotspotRenderer.setSize(
        this.getPhotosphereWidth(),
        this.getPhotosphereHeight()
      );
    }
  }

  /**
   * Get the clientX value from a mouse event, with fallback to mouse down hotspot dragX
   * @param event
   * @returns Detected clientX or 0 when not detected
   */
  public getClientX(event: any): number {
    let x: number = -1;
    if (event) {
      if (typeof event.clientX !== "undefined") {
        x = event.clientX;
      } else if (typeof event.touches !== "undefined" && event.touches.length > 0 && event.touches[0]) {
        x = event.touches[0].clientX;
      }
    }
    if (x === -1 && this.mousedownHotspot && this.mousedownHotspot?.dragX) {
      x = this.mousedownHotspot.dragX;
    }
    return x === -1 ? 0 : x;
  }

  /**
   * Get the clientY value from a mouse event, with fallback to mouse down hotspot dragY
   * @param event
   * @returns Detected clientY or 0 when not detected
   */
   public getClientY(event: any): number {
    let y: number = -1;
    if (event) {
      if (typeof event.clientY !== "undefined") {
        y = event.clientY;
      } else if (typeof event.touches !== "undefined" && event.touches.length > 0 && event.touches[0]) {
        y = event.touches[0].clientY;
      }
    }
    if (y === -1 && this.mousedownHotspot && this.mousedownHotspot?.dragY) {
      y = this.mousedownHotspot.dragY;
    }
    return y === -1 ? 0 : y;
  }

  /**
   * Check if photosphere can be rotated
   * @returns
   */
  public canRotate(): boolean {
    if (!this.photoSphereInteractive) {
      return false;
    }
    if (this.disablePhotosphereRotate) {
      return false;
    }
    if (this.currentPhotosphere && this.currentPhotosphere?.rotationParams?.disable) {
      return false;
    }
    if (this.currentPhotosphere?.type === "static") {
      return false;
    }
    return true;
  }

  /**
   * Handle mouse or touch start for photosphere and hotspots interactions
   * @param event
   * @returns
   */
  public onPointerStart(event: any): void {
    // Are we interacting with the editor modal?
    let is_editor_modal = event.target.classList.contains("editor-modal") || event.target.closest(".editor-modal");
    if (is_editor_modal) {
      return;
    }

    if (
      !this.photoSphereInteractive ||
      this.modalVisible ||
      event.button === 2
    ) {
      return;
    }

    this.isUserInteracting = true;

    const clientX = this.getClientX(event);
    const clientY = this.getClientY(event);

    if (clientY < 65 || clientY > window.innerHeight - 140) {
      this.isUserInteracting = false;
      return;
    }

    this.onMouseDownMouseX = clientX;
    this.onMouseDownMouseY = clientY;

    this.onMouseDownLon = this.lon;
    this.onMouseDownLat = this.lat;
    this.momentumDiff = 0;
    this.momentumX = clientX;
    this.startTime = new Date();

    if (this.momentum_tween) {
      this.momentum_tween.kill();
    }

    if (this.rotation_tween) {
      this.rotation_tween.kill();
    }
  }

  /**
   * Handle mouse and touch move events for photosphere and hotspots interactions
   * @param event
   * @returns
   */
  public onPointerMove(event: any): void {
    // Are we interacting with the editor modal?
    let is_editor_modal = event.target.classList.contains("editor-modal") || event.target.closest(".editor-modal");
    if (is_editor_modal) {
      return;
    }
    // Pointer position
    const clientX = this.getClientX(event);
    const clientY = this.getClientY(event);
    // Drag a hotspot
    if (this.editable && this.mousedownHotspot) {
      const startX = this.mousedownHotspot.hsStartX ? this.mousedownHotspot.hsStartX : this.mousedownHotspot.x;
      const startY = this.mousedownHotspot.hsStartY ? this.mousedownHotspot.hsStartY : this.mousedownHotspot.y;
      let hs_x = startX - (this.onMouseDownMouseX - clientX);
      let hs_y = startY + (clientY - this.onMouseDownMouseY);
      this.mousedownHotspot.dragX = hs_x;
      this.mousedownHotspot.dragY = hs_y;
      this.mousedownHotspot.x = hs_x;
      this.mousedownHotspot.y = hs_y;
      this.photosphereSvc.hotspotX.next(hs_x);
      this.photosphereSvc.hotspotY.next(hs_y);
      if (this.mousedownHotspot?.threeJs && this?.currentPhotosphere) {
        const forward = this.getPositionFromEquirectangularSpace(hs_x * this.currentPhotosphere.mainScale, hs_y * this.currentPhotosphere.mainScale);
        this.mousedownHotspot.threeJs.position.x = forward.x;
        this.mousedownHotspot.threeJs.position.y = forward.y;
        this.mousedownHotspot.threeJs.position.z = forward.z;
        if (this.hotspotRenderer && this.scene && this.camera) {
          this.hotspotRenderer.render(this.scene, this.camera);
        }
      }
      return;
    }
    // Ignore when photosphere rotation is disabled
    if (!this.canRotate()) {
      return;
    }
    // Ignore a modal is being shown
    if (this.modalVisible) {
      return;
    }
    // Rotate photosphere or...
    if (this.isUserInteracting === true) {
      this.lon = (this.onMouseDownMouseX - clientX) * 0.1 + this.onMouseDownLon;
      this.lat = (clientY - this.onMouseDownMouseY) * 0.1 + this.onMouseDownLat;
      this.hideControls = true;
      if (this.scroll_timeout) {
        clearTimeout(this.scroll_timeout);
      }
      // when no new scroll event fires, tween momentum effect
      if (this.config.momentum) {
        this.scroll_timeout = setTimeout(() => {
          this.momentumDiff = (this.momentumX - clientX) * 0.1;
          // this.tweenMomentum(this.onMouseDownLon - this.lon);
          if (!this.isUserInteracting) {
            const now: any = new Date();
            const time: any = (now - this.startTime) * 0.02;
            this.speed = this.momentumDiff / time;
            this.acceleration = this.speed / time;

            this.tweenMomentum(this.acceleration);
          } else {
            // this.momentumX = clientX;
          }
        }, this.config.scroll_timeout);
      }
    } else {
      const id = this.pickHelper?.pick(
        { x: event.clientX, y: event.clientY },
        this.pickingScene,
        this.camera
      );
      if (id === 0xff00ff) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "auto";
      }
    }
  }

  /**
   * Handle mouseup and toucheend events for photospheres and hotspots
   * @param event
   */
  public onPointerUp(event: any): void {
    // No more mouse interaction with the photosphere
    this.isUserInteracting = false;
    // Are we interacting with the editor modal?
    let is_editor_modal = event.target.classList.contains("editor-modal") || event.target.closest(".editor-modal");
    // Editor features
    if (!is_editor_modal && this.editable) {
        // Are we clicking or dragging?
        let dragging: boolean = false;
        if (this.mousedownHotspot && this.dragDistance > 0 && this.mousedownHotspot?.dragX && this.mousedownHotspot?.dragY) {
          const startX = this.mousedownHotspot.hsStartX ? this.mousedownHotspot.hsStartX : this.mousedownHotspot.x;
          const startY = this.mousedownHotspot.hsStartY ? this.mousedownHotspot.hsStartY : this.mousedownHotspot.y;
          if (Math.abs(this.mousedownHotspot?.dragX - startX) > this.dragDistance || Math.abs(this.mousedownHotspot?.dragY - startY) > this.dragDistance) {
            dragging = true;
          }
        }
        // Detect mouse event on hotspot
        let is_hotspot = event.target.classList.contains("hotspot") || event.target.closest(".hotspot");
        // Log updated hotspot coordinates
        // Update hotspot coordinates to match drag coordinates
        if (this.mousedownHotspot) {
            if (this.mousedownHotspot?.dragX && this.mousedownHotspot?.dragY) {
              this.mousedownHotspot.x = this.mousedownHotspot.dragX;
              this.mousedownHotspot.y = this.mousedownHotspot.dragY;
            }
            console.count(this.mousedownHotspot?.id);
            console.log(
                '%c' + this.mousedownHotspot?.id + " coordinates - { x: " + this.mousedownHotspot.x + ", y: " + this.mousedownHotspot.y + " }",
                'color: #fff; padding: 4px; background-color: orange; font-weight: bold'
            );
        }
        this.mousedownHotspot = null;
    }
  }

  public tweenLinearMomentum(x: number): void {
    if (this.momentum_tween) {
      this.momentum_tween.kill();
    }

    this.linear_tween = TweenMax.to(this, this.config.linear_duration, {
      lon: `+=${this.config.linear_distance_factor * x}`,
      ease: Power0.easeNone,
    });
  }

  public tweenMomentum(x: number): void {
    if (this.linear_tween) {
      this.linear_tween.kill();
    }

    this.momentum_tween = TweenMax.to(this, this.config.momentum_duration, {
      lon: `+=${this.config.momentum_distance_factor * x}`,
      ease: Power2.easeOut,
    });
  }

  hexToBin(hex: any): number {
    const r = hex >> 16;
    const g = (hex >> 8) & 0xff;
    const b = hex & 0xff;
    const id = (r << 16) | (g << 8) | (b << 0);

    return id;
  }

  public onDocumentMouseWheel(event: any): void {
    if (!this.photoSphereInteractive || this.modalVisible || !this.camera) {
      return;
    }

    const fov = this.camera.fov + event.deltaY * 0.05;

    this.camera.fov = THREE.MathUtils.clamp(fov, 30, 70);

    this.camera.updateProjectionMatrix();
  }

  public promptMotionControls() {
    (DeviceMotionEvent as any).requestPermission()
      .then((permissionState: any) => {
        if (permissionState === "granted") {
          this.permitMotionControls();
        }
      })
      .catch(console.error);
  }

  public permitMotionControls() {
    if (!this.camera) {
      return;
    }
    window.addEventListener('deviceorientation', this.handleOrientation.bind(this), true);
    this.motionControls = true;
    this.photosphereSvc.motionControls$.next(true);
    this.motionControlsPermitted = true;
    this.photosphereSvc.motionControlsPermitted$.next(true);
    this.controls = new DeviceOrientationControls(this.camera);
  }

  public toggleMotionControls() {
    if (!this.motionControlsPossible) {
      return;
    }

    this.hideControls = true;

    if (this.motionControlsPermitted) {
      this.motionControls = !this.motionControls;
      this.photosphereSvc.motionControls$.next(this.motionControls);
      // Revert to start position when toggling off
      if (this.currentPhotosphere && !this.motionControls) {
        this.lon = this.currentPhotosphere.rotationParams.startX ?? 0;
        this.lat = this.currentPhotosphere.rotationParams.startY ?? 0;
      }
    } else {
      this.promptMotionControls();
    }
  }

  public handleMotion(event: any): void {
    this.lon += event.acceleration.x;
    this.lat += event.acceleration.y;
  }

  public handleOrientation(event: any): void {
    if (this.currentPhotosphere && this.currentPhotosphere.rotationParams.startX && this.motionControls) {
      const gamma = event.gamma;
      const beta = event.beta;
      const alpha = event.alpha;
      const euler = new Euler();
      euler.set(beta, alpha, -gamma, "YXZ");
      this.lat = -90 + euler.x + this.currentPhotosphere.rotationParams.startX;
      this.lon = -90 - euler.y;
    }
  }

  /***************************************************************************
   * Hotspots
   ***************************************************************************/

  /**
   * Initialize hotspots for the current photosphere
   */
  public initHotspots(): void {
    if (!this.currentPhotosphere) {
      return;
    }
    this.hotspots.forEach((hotspot: Hotspot) => {
      if (hotspot?.threeJs) {
        this.scene?.remove(hotspot.threeJs);
        hotspot.threeJs.remove();
      }
      if (hotspot?.extra?.audio) {
        this.soundSvc.stopDirectionalSound(hotspot.extra.audio.id);
      }
    });
    this.currentPhotosphere.hotspots.forEach((hotspot: Hotspot) => {
      if (hotspot?.disabled) {
        return;
      }
      this.makeHotspotObject(hotspot);
      if (hotspot.threeJs) {
        this.scene?.add(hotspot.threeJs);
      }
      this.hotspots.push(hotspot);
    });
  }

  /**
   * Create a hotspots object.
   * - Generates HTML
   * - Binds click events
   * - Hotspot object for use with THREE.js
   * @param hotspot The hotspot definition found in photosphere data
   * @returns Hotspot
   */
  public makeHotspotObject(hotspot: Hotspot): Hotspot {
    const text = document.createElement("div");
    if (!hotspot.noDefaultStyles) {
      text.className = "photosphere-flag hotspot";
    }

    if (hotspot?.markerType) {
      text.className += " " + hotspot.markerType;
    }
    if (hotspot?.cssClass) {
      text.className += " " + hotspot.cssClass;
    }
    if (hotspot?.id) {
      text.className += " hotspot-" + hotspot.id.replace(".", "-") + " ";
    }

    text.appendChild(this.addHotspotSvg(hotspot));

    text.onmouseenter = (event) => {
      this.overlayPhotosphereHotspot(hotspot, "mouseenter");
      const hotspotEvent: any = {
        event: event,
        hotspot: hotspot
      };
      this.hotspotEnter.emit(hotspotEvent);
    };

    text.onmouseleave = (event) => {
      this.overlayPhotosphereHotspot(hotspot, "mouseleave");
      const hotspotEvent: any = {
        event: event,
        hotspot: hotspot
      };
      this.hotspotLeave.emit(hotspotEvent);
    };

    text.onmousedown = () => {
      if (this.editable) {
        this.mousedownHotspot = hotspot;
        this.mousedownHotspot.hsStartX = this.mousedownHotspot.x;
        this.mousedownHotspot.hsStartY = this.mousedownHotspot.y;
      }
    };

    text.onmouseup = (event: any) => {
      if (this.editable) {
        this.mousedownHotspot = hotspot;
      } else {
        this.hotspotClick(event, hotspot);
      }
    };

    // Build the text label
    if (hotspot.textLabel) {
      text.appendChild(this.createTextLabel(hotspot.textLabel));
    }

    // Build hover text
    if (hotspot.hoverType === "simple") {
      text.appendChild(
        this.createHoverText(hotspot)
      );
    } else if (hotspot.hoverType === "bubble") {
      text.appendChild(
        this.createHoverText(hotspot)
      );
    } else if (hotspot.hoverType === "extra" && hotspot.extraHover) {
      text.appendChild(
        this.createHoverExtra(
          hotspot.extraHover,
          hotspot.hoverCssClass ? hotspot.hoverCssClass : ""
        ));
    }

    // Retain reference to DOM element
    hotspot.elem = text;

    // Build ThreeJS marker object
    hotspot.threeJs = new CSS2DObject(hotspot.elem);
    const mainScale = this.currentPhotosphere ? this.currentPhotosphere.mainScale : 1;
    const forward = this.getPositionFromEquirectangularSpace(
      hotspot.x * mainScale,
      hotspot.y * mainScale
    );
    hotspot.threeJs.position.x = forward.x;
    hotspot.threeJs.position.y = forward.y;
    hotspot.threeJs.position.z = forward.z;

    //AUDIO 
    if(hotspot.extra?.audio) {
      this.soundSvc.playDirectionalSound(hotspot.extra.audio, this.photosphereSvc, true )
    }

    return hotspot;
  }

  /**
   * Build SVG icon DOM element for a hotspot
   * @param hotspot
   * @returns
   */
  public addHotspotSvg(hotspot: Hotspot): any {
    const icon = document.createElement("div");
    icon.classList.add("icon");
    let htmlString = "";
    if (hotspot.markerType === 'custom-hotspot' && hotspot.customHtml) {
      htmlString = hotspot.customHtml;
    } else {
      if (hotspot.clickType === "use up arrow svg?") {
        htmlString =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37"><polygon points="37 18.5 33.76 21.73 21 8.97 21 37 16 37 16 8.97 3.24 21.73 0 18.5 18.5 0 37 18.5"/></svg>';
      } else {
        htmlString =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37"><polygon points="37 16 37 21 21 21 21 37 16 37 16 21 0 21 0 16 16 16 16 0 21 0 21 16 37 16"/></svg>';
      }
    }
    let transform = '';
    if (hotspot?.markerRotation) {
      transform += `rotate(${hotspot.markerRotation}deg) `;
    }
    if (hotspot?.scale) {
      transform += `scale(${hotspot.scale}) `;
    }
    icon.style.transform = transform;
    icon.innerHTML = htmlString;
    return icon;
  }

  /**
   * Calculate THREE.js coordinates from x,y coords in a photosphere
   * @param x
   * @param y
   * @returns
   */
  public getPositionFromEquirectangularSpace(x: number, y: number): THREE.Vector3 {
    const xfrac = this.mainTexture ? x / this.mainTexture.image?.width : 1;
    const yfrac = this.mainTexture ? y / this.mainTexture.image?.height : 1;
    const xrot = 2 * Math.PI * xfrac;
    const yrot = Math.PI * (yfrac - 0.5);
    const forward = new THREE.Vector3(50, 0, 0);
    forward.applyAxisAngle(new THREE.Vector3(0, 0, -1), yrot);
    forward.applyAxisAngle(new THREE.Vector3(0, -1, 0), xrot);
    return forward;
  }

  /**
   * Build DOM element with hover text for a hotspot
   * @param hotspot
   * @returns
   */
  public createHoverText(hotspot: Hotspot): any {
    const hoverDiv = document.createElement("div");
    if (hotspot?.hoverType === "bubble") {
      hoverDiv.classList.add("hotspot-bubble");
    } else {
      hoverDiv.classList.add("hovertext-simple");
    }

    if (hotspot?.cssClass) {
      hoverDiv.classList.add(hotspot?.cssClass);
    }

    let htmlString = "";
    if (hotspot?.hoverTextHtml) {
      htmlString = hotspot.hoverTextHtml;
    } else if (hotspot?.hoverText) {
      htmlString = `<p>${hotspot.hoverText}</p>`;
    }
    hoverDiv.innerHTML = htmlString;

    return hoverDiv;
  }

  /**
   * Build extra hover DOM element for a hotspot
   * @param extraHover
   * @returns
   */
  public createHoverExtra(extraHover: any, cssClass: string = ""): any {
    const hoverDiv = document.createElement("div");
    hoverDiv.classList.add("hotspot-extra");
    hoverDiv.classList.add(
      extraHover.position ? extraHover.position : "bottom"
    );
    if (cssClass) {
      hoverDiv.classList.add(cssClass);
    }

    if (extraHover.position === "top") {
      hoverDiv.classList.add("secondary");
    }

    if (extraHover.text) {
      hoverDiv.innerHTML = extraHover.text;
      if (extraHover.text_size) {
        hoverDiv.style.fontSize = extraHover.text_size + "px";
      }
    } else if (extraHover.image) {
      const img = document.createElement("img");
      img.src = extraHover.image;
      if (extraHover.image_max_width) {
        img.style.maxWidth = extraHover.image_max_width + "px";
      }
      hoverDiv.append(img);
    }

    return hoverDiv;
  }


  /**
   * Create DOM element for hotspot text label
   * @param label
   * @param cssClass
   * @returns
   */
  public createTextLabel(label: string, cssClass: string = ""): any {
    const div = document.createElement("div");
    div.classList.add("hotspot-label");
    if (cssClass) {
      div.classList.add(cssClass);
    }
    div.innerHTML = `<div class="hotspot-label-text">${label}</div>`;
    return div;
  }

  /**
   * Click callback handler for a hotspot
   * @param hotspot
   */
  public hotspotClick(event: any, hotspot: Hotspot): void {
    // Hotspot click event - cancellable
    const hotspotEvent: any = {
      canceled: false,
      event: event,
      hotspot: hotspot
    };
    this.hotspotClicked.emit(hotspotEvent);
    if (hotspotEvent.canceled) {
      return;
    }

    // Hide the navigation controls description
    this.hideControls = true;

    // Navigation: internal or external
    if (hotspot?.clickUrl) {
      if (hotspot.clickType === "internal") {
        this.router.navigate([hotspot.clickUrl]);
      } else if (hotspot.clickType === "external") {
        const windowTarget = hotspot.windowTarget ? hotspot.windowTarget : "_blank";
        window.open(hotspot.clickUrl, windowTarget);
      }
    }
  }

}


/***************************************************************************
 * GPUPickHelper class
 ***************************************************************************/
export class GPUPickHelper {
  public pickingTexture: THREE.WebGLRenderTarget;
  public pixelBuffer: Uint8Array;
  public renderer: THREE.WebGLRenderer;

  constructor(r: THREE.WebGLRenderer) {
    // create a 1x1 pixel render target
    this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
    this.pixelBuffer = new Uint8Array(4);
    this.renderer = r;
  }
  pick(cssPosition: any, scene: THREE.Scene | undefined, camera: THREE.PerspectiveCamera | undefined) {

    if (!camera || !scene) {
      return 0;
    }

    const { pickingTexture, pixelBuffer } = this;

    // set the view offset to represent just a single pixel under the mouse
    const pixelRatio = this.renderer.getPixelRatio();
    camera.setViewOffset(
      this.renderer.getContext().drawingBufferWidth, // full width
      this.renderer.getContext().drawingBufferHeight, // full top
      (cssPosition.x * pixelRatio) | 0, // rect x
      (cssPosition.y * pixelRatio) | 0, // rect y
      1, // rect width
      1 // rect height
    );
    // render the scene
    this.renderer.setRenderTarget(pickingTexture);
    this.renderer.render(scene, camera);
    this.renderer.setRenderTarget(null);
    // clear the view offset so rendering returns to normal
    camera.clearViewOffset();
    // read the pixel
    this.renderer.readRenderTargetPixels(
      pickingTexture,
      0, // x
      0, // y
      1, // width
      1, // height
      pixelBuffer
    );

    const id =
      (pixelBuffer[0] << 16) | (pixelBuffer[1] << 8) | (pixelBuffer[2] << 0);
    if (pixelBuffer[3] == 0) {
      return 0;
    }

    return id;
  }
}


// SHADERS

const motionBlurVertexShader = `
	varying vec2 vUv;

	void main() {

		gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		vUv = uv;

	}`

const motionBlurFragmentShader = `
	varying vec2 vUv;

	uniform sampler2D tDepth;
	uniform sampler2D tColor;

	uniform mat4 clipToWorldMatrix;
	uniform mat4 previousWorldToClipMatrix;

	uniform vec3 cameraMove;

	uniform float velocityFactor;
	uniform float delta;

	void main() {

		float zOverW = texture2D(tDepth, vUv).x;

		// clipPosition is the viewport position at this pixel in the range -1 to 1.
		vec4 clipPosition = vec4(vUv.x * 2. - 1., vUv.y * 2. - 1., zOverW * 2. - 1., 1.);

		vec4 worldPosition = clipToWorldMatrix * clipPosition;
		worldPosition /= worldPosition.w;

		vec4 previousClipPosition = worldPosition;

		// Reduce motion blur due to camera translation especially at the screen center.
		previousClipPosition.xyz -= cameraMove * (
			1. - smoothstep(.3, 1., clamp(length(clipPosition.xy), 0., 1.))
		);

		previousClipPosition = previousWorldToClipMatrix * previousClipPosition;
		previousClipPosition /= previousClipPosition.w;

		vec2 velocity = velocityFactor * (clipPosition - previousClipPosition).xy / delta * 16.67;

		vec4 finalColor = vec4(0.);
		vec2 offset = vec2(0.);
		float weight = 0.;
		const int samples = 20;
		for(int i = 0; i < samples; i++) {
    			offset = velocity * (float(i) / (float(samples) - 1.) - .5);
    			vec4 c = texture2D(tColor, vUv + offset);
			finalColor += c;
		}
		finalColor /= float(samples);
		gl_FragColor = vec4(finalColor.rgb, 1.);

		// debug: view velocity values
		// gl_FragColor = vec4(abs(velocity), 0., 1.);

		// debug: view depth buffer
		// gl_FragColor = vec4(vec3(zOverW), 1.);
	}`

const motionBlurShader = {

    uniforms: {
        tDepth: { type: 't', value: null },
        tColor: { type: 't', value: null },

        velocityFactor: { type: 'f', value: 1 },
        delta: { type: 'f', value: 16.67 },

        clipToWorldMatrix: { type: 'm4', value: new THREE.Matrix4() },
        previousWorldToClipMatrix: { type: 'm4', value: new THREE.Matrix4() },

        cameraMove: { type: 'v3', value: new THREE.Vector3() }
    },

    vertexShader: motionBlurVertexShader,
    fragmentShader: motionBlurFragmentShader
}
