<div class="header-simple" [ngClass]="{'off': !showMe}">
    <div class="inner">
        <div class="bg">
            <!-- @todo Put SVGs for animation here? -->
        </div>
        <div class="logo">
            <a [routerLink]="['/']">
                <img src="/assets/images/larabar-logo.png" alt="LÄRABAR" />
            </a>
        </div>
    </div>
</div>