<div class="header-nav" [class.on]="on" [class.off]="!on">
    <div class="inner">
        <div class="h-cols">
            <div class="h-col-1">
                <a [routerLink]="['/farmer']">
                    <img src="/assets/images/larabar-logo.png" alt="LÄRABAR" class="logo-desktop" />
                    <img src="/assets/images/larabar-icon.png" alt="LÄRABAR" class="logo-mobile" />
                </a>
            </div>
            <div class="h-col-2">
                <div class="h-col-2-1">
                    <div class="h-speaker-button">
                        <button
                            type="button"
                            class="btn-control speaker-button"
                            [class.off]="!uiSvc.audioEnabled$.value"
                            (click)="speakerClick()"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" width="18.354" height="13.311" viewBox="0 0 18.354 13.311"
                            >
                                <defs>
                                <clipPath id="speaker-button-clip-path">
                                    <rect width="18.354" height="13.311" fill="#fff"/>
                                </clipPath>
                                </defs>
                                <g clip-path="url(#speaker-button-clip-path)">
                                <path d="M8.121,13.311a.7.7,0,0,1-.472-.185L4.722,10.407H1.1A1.1,1.1,0,0,1,0,9.306V4A1.1,1.1,0,0,1,1.1,2.9H4.721L7.648.185A.7.7,0,0,1,8.092,0h.027a1.1,1.1,0,0,1,1.1,1.1V12.21a1.1,1.1,0,0,1-1.1,1.1ZM1.385,9.022H4.994a.688.688,0,0,1,.471.185l2.371,2.2V1.9L5.465,4.1a.691.691,0,0,1-.471.185H1.385Z" fill="#fff"/>
                                <path class="wave wave1" d="M169.447,68.994a.693.693,0,0,1-.5-1.17,1.613,1.613,0,0,0,0-2.188.693.693,0,0,1,1-.953,3,3,0,0,1,0,4.1.691.691,0,0,1-.5.215" transform="translate(-158.176 -60.441)" fill="#fff"/>
                                <path class="wave wave2" d="M205.044,47.126a.693.693,0,0,1-.5-1.17,3.886,3.886,0,0,0,.062-5.22v0l-.062-.066a.692.692,0,0,1,1-.954,5.271,5.271,0,0,1,0,7.2.689.689,0,0,1-.5.216Z" transform="translate(-191.541 -37.023)" fill="#fff"/>
                                <path class="wave wave3" d="M242.417,27.118a.693.693,0,0,1-.5-1.171,6.079,6.079,0,0,0,0-8.276.693.693,0,1,1,1-.954,7.465,7.465,0,0,1,0,10.185A.694.694,0,0,1,242.417,27.118Z" transform="translate(-226.571 -15.467)" fill="#fff"/>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="h-col-2-2">
                    <div class="h-gyro-button" *ngIf="gyroVisible">
                        <button
                            type="button"
                            class="btn-control gyro-button"
                            [class.off]="!gyroEnabled"
                            (click)="gyroClick()"
                        >
                        <svg 
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24.408" height="20.408" viewBox="0 0 24.408 20.408"
                        >
                            <defs>
                            <clipPath id="gyro-button-clip-path">
                                <rect width="19.789" height="19.789" fill="#fff"/>
                            </clipPath>
                            </defs>
                            <g transform="translate(-4.796 -6.796)">
                            <g transform="translate(7.105 7.105)">
                                <g clip-path="url(#gyro-button-clip-path)">
                                <path d="M92.213,23.54h.014a.494.494,0,0,0,.493-.478c.167-5.157,1.024-6.319,1.315-6.319.36,0,.995.567,1.231,4.363a.493.493,0,0,0,.985-.06,14.438,14.438,0,0,0-.616-3.842c-.35-.96-.892-1.443-1.6-1.443-1.167,0-1.6,1.7-1.738,2.265a24.937,24.937,0,0,0-.562,5,.5.5,0,0,0,.478.509Z" transform="translate(-84.205 -14.468)" fill="#fff"/>
                                <path d="M131.1,91.613a.49.49,0,0,0,.478.507c5.157.167,6.319,1.024,6.319,1.315,0,.36-.567.995-4.363,1.231a.493.493,0,0,0,.03.985h.03a14.438,14.438,0,0,0,3.842-.616c.96-.35,1.443-.892,1.443-1.6,0-1.167-1.7-1.6-2.265-1.738a24.944,24.944,0,0,0-5-.562.5.5,0,0,0-.509.478Z" transform="translate(-120.335 -83.654)" fill="#fff"/>
                                <path d="M106.02,106.61a1.246,1.246,0,1,0,1.246-1.246,1.247,1.247,0,0,0-1.246,1.246m1.246-.261a.261.261,0,1,1-.261.261.259.259,0,0,1,.261-.261" transform="translate(-97.317 -96.715)" fill="#fff"/>
                                <path d="M9.895,0a9.895,9.895,0,1,0,9.895,9.895A9.9,9.9,0,0,0,9.895,0Zm0,18.8a8.91,8.91,0,1,1,8.91-8.91,8.919,8.919,0,0,1-8.91,8.91" fill="#fff"/>
                                <path d="M99.551,130.5a.494.494,0,0,0-.507.478c-.167,5.157-1.024,6.319-1.315,6.319-.36,0-.995-.567-1.231-4.363a.493.493,0,1,0-.985.06,14.438,14.438,0,0,0,.616,3.842c.35.96.892,1.443,1.6,1.443,1.167,0,1.6-1.7,1.738-2.265a24.938,24.938,0,0,0,.562-5,.5.5,0,0,0-.478-.509Z" transform="translate(-87.672 -119.787)" fill="#fff"/>
                                <path d="M23.716,99.371a.493.493,0,0,0,.014-.985c-5.156-.167-6.318-1.025-6.318-1.315,0-.36.567-.995,4.363-1.231a.493.493,0,1,0-.06-.985,14.438,14.438,0,0,0-3.842.616c-.96.35-1.443.892-1.443,1.6,0,1.167,1.7,1.6,2.265,1.738a24.943,24.943,0,0,0,5,.562h.015Z" transform="translate(-15.082 -87.068)" fill="#fff"/>
                                </g>
                            </g>
                            <line class="off-element" x2="23" y2="19" transform="translate(5.5 7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                                                
                        </button>
                    </div>
                    <div class="h-drawer-button drawer-container">
                        <button
                            type="button"
                            class="btn-square drawer-button drawer-toggle"
                            [class.off]="!uiSvc.drawerOpen$.value"
                            (click)="drawerClick()"
                        >
                            <div class="hamburger" [class.open]="uiSvc.drawerOpen$.value">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div class="drawer-mobile-bg" [class.enabled]="drawerBgEnabled" [class.open]="drawerBgOpen" (click)="drawerBgClick()"></div>
                        <div class="drawer" [class.open]="uiSvc.drawerOpen$.value" [class.enabled]="drawerBgEnabled">
                            <div class="drawer-inner">
                                <div class="drawer-content" [class.scrollable]="drawerScroll">
                                    <ul class="drawer-nav">
                                        <li class="nav-item" *ngFor="let item of footerNavStates">
                                            <a
                                                class="nav-link"
                                                [routerLink]="[ item.url ]"
                                                routerLinkActive="active-route"
                                                [class.active]="item.active"
                                                (click)="navClick($event)"
                                            >{{item.title}}</a>
                                        </li>
                                    </ul>
                                    <div class="drawer-button-line">
                                        <a class="button-rectangle" (click)="showTutorial($event)" target="_blank">HOW TO NAVIGATE</a>
                                    </div>
                                    <div class="drawer-button-line">
                                        <a class="button-rectangle alt" href="https://www.larabar.com/our-products/larabar" target="_blank">SHOP LARABAR</a>
                                    </div>
                                    <ul class="simple-nav">
                                        <li class="nav-item"><a class="nav-link" href="https://www.generalmills.com/en/Company/legal-terms" target="_blank">Legal Terms</a></li>
                                        <li class="nav-item"><a class="nav-link" href="https://www.generalmills.com/en/Company/privacy-policies/privacy-policy-cookie-US-English" target="_blank">Privacy Policy</a></li>
                                        <li class="nav-item"><a class="nav-link" href="https://privacy.generalmills.com/" target="_blank">Your Privacy Choices <img src="/assets/images/privacy-options-icon.png" alt="" class="privacy-icon" /></a></li>
                                        <li class="nav-item"><a class="nav-link" href="https://www.generalmills.com/privacy-security/us-english-privacy-policy#cookienotice" target="_blank">Cookie Notice</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#" (click)="cookieSettingsClick($event)">Customize Cookie Settings</a></li>
                                    </ul>
                                    <div class="copyright">© 2003-2023 | LÄRABAR, all rights reserved</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>