import { Component, OnInit } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhotosphereService } from './services/photosphere.service';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { UtilsService } from './services/utils.service';
import { Photosphere } from './models';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from './services/ui.service';
import { ModalService } from './services/modal.service';
import Modals from '../assets/data/modals.json';
import { Modal, Point } from './models/modal';
import { SoundService } from './services/sound.service';

declare const window: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    // Manually set background image
    public backgroundImageSubject: BehaviorSubject<any>;

    // Previous background image
    public currentBgImg: any = '';

    // Passed along to Photospheres component for loading scene data
    public photospheresDataObservable!: Observable<any>;

    // Skip executing the next mouseleave event for a hotspot
    // Prevents background image swap confusion
    public skipNextLeaveEvent: boolean = false;

    // Audio toggle - true if enabled
    public audioEnabled: boolean = false;

    // Gyroscope enabled
    public gyroEnabled = false;

    // If browser supports motion controls
    public motionControlsPossible = false;

    // If user has given motion controls permission
    public motionControlsPermitted = false;

    // Current navigation URL
    public currentUrl: string | undefined | null;

    // Show the intro video component?
    public showIntroVideo: boolean = false;

    // Used by `takeUntil` detect when both start and tutorial modals have been dismissed
    public tutorialEnded$: Subject<boolean> = new Subject();

    // Is the modal in fullscreen video mode?
    public fullscreen: boolean = false;

    constructor(
        private photosphereSvc: PhotosphereService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utilsSvc: UtilsService,
        public sanitizer: DomSanitizer,
        public uiSvc: UiService,
        public modalSvc: ModalService,
        public soundSvc: SoundService
    ) {
        this.backgroundImageSubject = this.utilsSvc.backgroundImageSubject;
    }

    ngOnInit(): void {
        this.utilsSvc.initLogs();
        this.subscribe();
        this.utilsSvc.initAudio('main');
        this.utilsSvc.initAudio('hotspot');
        this.loadData();
        this.soundSvc.preloadSounds();
    }

    /**
     * Subscribe to events
     */
    public subscribe(): void {
        // Listen to router Events

        this.activatedRoute.queryParams.subscribe(p => {
            const params = Object.assign({}, p);
            delete params.dapp;
            // this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
        });

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.url !== val.urlAfterRedirects) {
                    location.reload();
                } else {
                    this.soundSvc.play("Ambiance", true);
                    this.embedConsentScript();
                }
                // Actions to trigger only once the URL has changed
                if (this.currentUrl !== val.url) {
                    this.currentUrl = val.url.split('?')[0];
                    // Change UI elements based on current URL
                    switch (this.currentUrl) {
                        case "/":
                            this.showIntroVideo = true;
                            this.uiSvc.photosphereEnabled$.next(false);
                            // Set header to simple and then hide for animation in. 
                            this.uiSvc.headerType$.next("simple");
                            this.utilsSvc.showHeader.next(false);
                            this.modalSvc.modalVisible.next(false);
                            break;
                        default:
                            this.showIntroVideo = false;
                            this.uiSvc.photosphereEnabled$.next(true);
                            this.uiSvc.headerType$.next("nav");
                            this.utilsSvc.showHeader.next(false);
                        //this.embedConsentScript();
                    }
                    // Set footer nav state from URL change
                    this.uiSvc.footerNavStateFromUrl(val.url);
                }
            }
        });

        // Expose current photosphere to other components in this project
        // Also add the current photosphere as a class to the <body> element
        this.photosphereSvc.currentPhotosphere.subscribe((data: Photosphere | null) => {
            this.utilsSvc.currentPhotosphere.next(data);
            this.setBodyClass(data);
        });

        // Expose scene/photosphere/modal/router data to other components in this project
        this.photosphereSvc.dataSubject.subscribe((data) => {
            this.utilsSvc.dataSubject.next(data);
        });

        this.photosphereSvc.photosphereLoaded.subscribe(loaded => {

        });

        // Listen to service to allow audio
        this.utilsSvc.audioEnabled.subscribe(enabled => {
            this.audioEnabled = enabled;
        });

        // Listen for header type changes
        this.uiSvc.headerType$.subscribe((headerType) => {
            console.log(headerType);
        });

        this.modalSvc.modalFullScreen.subscribe(full => {
            console.log("FULL", full);
            this.fullscreen = full;
        })
    }

    /**
     * Open the tutorial / instructions modal
     */
    public openTutorial(): void {
        if (this.isMobile()) {
            this.showModal('start_mobile');
        } else {
            this.showModal('start');
        }
        this.uiSvc.showInstructions$.next(true);
    }

    isMobile(): boolean {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // true for mobile device
            return true;
        } else {
            // false for not mobile device
            return false;
        }
    }

    isLandscape(): boolean {
        if (this.isMobile()) {
            return window.innerWidth > window.innerHeight;
        } else {
            return false;
        }
    }

    /**
     * Toggle Audio
     * Enable or disable global audio
     */
    public toggleAudio(): void {
        this.utilsSvc.setAudioEnabled(!this.audioEnabled);
    }

    /**
     * Load Data
     * Send local json data to photosphere service to load
     */
    public loadData(): void {
        this.photosphereSvc.loadProject('larabar', '../assets/data/data.json');
        // this.photosphereSvc.loadProject("hi-larabar-stage");
    }

    /**
     * Bind additional functionality to hotspot click events
     * @param {any} $event data from hotspot click
     */
    public onHotspotClick($event: any) {
        console.log('Event', $event)
        $event.canceled = true;
        if ($event?.hotspot && $event.hotspot.id) {
            this.soundSvc.play("Hotspot")
            console.log("Photosphere Click");
            setTimeout(() => {
                if ($event.hotspot.clickUrl && $event.hotspot.clickType === 'internal') {
                    let point: Point = { x: $event.event?.clientX ? $event.event.clientX : (window.innerWidth >> 1), y: ($event.event?.clientY) ? $event.event.clientY : (window.innerHeight >> 1) }
                    this.utilsSvc.showDotTransition.next(point);
                    this.router.navigateByUrl($event.hotspot.clickUrl);
                } else if ($event.hotspot.clickType === 'modal') {
                    this.showModal($event.hotspot.modalId, $event.event);
                } else {
                    window.open($event.hotspot.clickUrl);
                }
            }, 1);
        }
    }

    /**
     * Hotspot hover: update photosphere image
     * @param {any} $event data from hotspot click
     */
    public onHotspotEnter($event: any) {
        if (!this.utilsSvc.isMobile.getValue()) {
            if ($event?.hotspot && $event.hotspot?.extra && $event.hotspot.extra?.overlayPhotosphere) {
                this.photosphereSvc.updateOverlayPhotosphere({
                    id: $event.hotspot.extra.overlayPhotosphere,
                    opacity: 1,
                    animate: true,
                    duration: 0.35
                });
            }
        }
    }

    /**
     * Hotspot stop hover: revert to photosphere image
     * @param $event
     */
    public onHotspotLeave($event: any) {
        if (!this.utilsSvc.isMobile.getValue()) {
            if (this.skipNextLeaveEvent) {
                this.skipNextLeaveEvent = false;
            } else {
                if ($event?.hotspot && $event.hotspot?.extra && $event.hotspot.extra?.overlayPhotosphere) {
                    this.photosphereSvc.updateOverlayPhotosphere({
                        id: $event.hotspot.extra.overlayPhotosphere,
                        opacity: 0,
                        animate: true,
                        duration: 0.35
                    });
                }
            }
        }
    }

    /**
     * Set the <body> CSS class that represents the current photosphere
     * @param {Photosphere} photosphere
     */
    public setBodyClass(photosphere: Photosphere | null) {
        let toRemove = '';

        document.body.classList.forEach((className: string) => {
            if (!toRemove && className.indexOf('ps-') === 0) {
                toRemove = className;
            }
        });

        if (toRemove) {
            document.body.classList.remove(toRemove);
        }

        if (photosphere?.id) {
            document.body.classList.add('ps-' + photosphere.id);
        }
    }

    /**
     * Show a modal by ID
     * @param id 
     */
    public showModal(id: string, event: any = null): void {
        let data = (Modals as Modal[]).find(modal => modal.id === id)

        if (data) {
            if (event) {
                data.clickPosition = { x: event?.clientX ? event.clientX : (window.innerWidth >> 1), y: (event?.clientY) ? event.clientY : (window.innerHeight >> 1) }
            }
            this.modalSvc.modalVisible.next(true);
            this.modalSvc.modalData.next(data);
        } else {
            console.error("MODAL ID NOT FOUND")
        }
    }

    /**
     * Add the cookie consent script.
     * <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="7b95ea91-9497-4c67-b3b4-843876231b7a"></script>
     */
    public embedConsentScript() {
        const existingTag = document.getElementById("larabar_cookie_consent_script");
        if (existingTag) {
            return;
        }
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('id', 'larabar_cookie_consent_script');
        scriptTag.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
        scriptTag.setAttribute('data-document-language', 'true');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('charset', 'UTF-8');
        scriptTag.setAttribute('data-domain-script', '7b95ea91-9497-4c67-b3b4-843876231b7a');
        document.head.appendChild(scriptTag);
    }

    /**
     * Show enter modal and tutorial modal once the intro video has stop playing.
     * Detect when both these modals have been dimissed, and navigate to Our Mission photosphere. 
     * Also, embed cookie consent once intro video has stopped playing. 
     */
    public onVideoEnded() {
        // Embed the cookie consent script
        // this.embedConsentScript();
        // Show tutorial modal.
        this.openTutorial();
        // Reset tutorial ended state
        this.tutorialEnded$.next(false);
        // Navigate away from home page once tutorial modal is dismissed.
        this.modalSvc.modalData.pipe(
            takeUntil(this.tutorialEnded$)
        ).subscribe((data) => {
            if (data?.id === 'tutorial') {
                this.modalSvc.modalVisible.pipe(
                    takeUntil(this.tutorialEnded$)
                ).subscribe((visible) => {
                    if (visible === false) {
                        this.tutorialEnded$.next(true);
                        let point: Point = { x: (window.innerWidth >> 1), y: (window.innerHeight >> 1) };
                        this.utilsSvc.showDotTransition.next(point);
                        setTimeout(() => {
                            this.router.navigate(['/christine']);
                        }, 1000);

                    }
                });
            }
        });
    }
}
