import { Component, Input, OnInit } from '@angular/core';
import { Modal } from 'src/app/models/modal';
import { ModalService } from 'src/app/services/modal.service';
import { UtilsService } from 'src/app/services/utils.service';
import Modals from 'src/assets/data/modals.json'

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  lottiePlayer: any

  @Input() close: any;

  constructor(private modalSvc:ModalService, private utilsSvc:UtilsService) { }

  ngOnInit(): void {
  }

  public showGuide():void {
    console.log('Show the guide. ');
    
    
    let data = (Modals as Modal[]).find(modal => modal.id === ((this.utilsSvc.isMobile.value) ? 'guide_m' : 'guide_d'));  
      this.modalSvc.modalVisible.next(true);
      this.modalSvc.modalData.next(data);
    
  }

}
