import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  @ViewChild(HTMLVideoElement) videoPlayer!: HTMLVideoElement;
  @Input() close: any;

  public videoURL: string = '../../../assets/videos/larabar_trailer_d.mp4';
  fullscreen: boolean = false;

  constructor(private utilsSvc:UtilsService) { }

  ngOnInit(): void {
    if (this.utilsSvc.isMobile.value) {
      // this.videoURL = '../../../assets/videos/larabar_trailer_m.mp4';
    }
    console.log('_Video', this.videoPlayer
    );
    
  }

  pause() {
    if (!this.videoPlayer) {
      this.videoPlayer = this.videoPlayer as HTMLVideoElement
    }

    if (this.videoPlayer && !this.videoPlayer.paused) {
      console.log(this.videoPlayer?.paused)
      this.videoPlayer?.pause();
    }
  }

  play() {
    if (!this.videoPlayer) {
      this.videoPlayer = this.videoPlayer as HTMLVideoElement
    }

    if (this.videoPlayer && this.videoPlayer?.paused) {
      this.videoPlayer?.play();
    }
  }
  toggleFullscreen() {
    // let full = !this.fullscreen;
    // this.fullscreen = !this.fullscreen;
    // this.modalSvc.modalFullScreen.next(this.fullscreen);

    // if (!this.textBox) {
    //   this.textBox = this._text.nativeElement as HTMLElement;
    //   console.log('text-box', this.textBox);
    // }
    // if (!this.innerModal) {
    //   this.innerModal = this._innerModal.nativeElement as HTMLElement;
    //   console.log('MODAL', this.innerModal);
    // }
    // if (full) {
    //   this.textBoxH = this.textBox.offsetHeight;
    //   console.log('this.textBoxH', this.textBoxH);
    //   let h = window.innerHeight;
    //   let w = window.innerWidth;
    //   this.modalDims.h = this.innerModal.offsetHeight;
    //   this.modalDims.w = this.innerModal.offsetWidth;
    //   console.log('this.modalDims', this.modalDims);

    //   gsap.to(this.textBox, { duration: .3, ease: "power2.out", height: 0, opacity: 0, display: "none" });
    //   gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: h, width: w, onComplete: this.play.bind(this) });
    // } else {
    //   gsap.to(this.textBox, { display: "block", duration: .2, delay: .1, height: this.textBoxH });
    //   gsap.to(this.textBox, { duration: .3, delay: .6, ease: "linear", opacity: 1 });
    //   gsap.to(this.innerModal, { duration: .6, ease: "power2.inOut", height: this.modalDims.h, width: this.modalDims.w });
    // }
  }

}
