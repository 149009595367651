import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Photosphere, PhotosphereUpdate, Hotspot, Route, RouteType, RoutePredicate, Modal, Project, ProjectData } from '../models';
import { PhotosphereComponent } from '../components/photosphere/photosphere.component';
import * as THREE from "three";

@Injectable({
  providedIn: 'root'
})
export class PhotosphereService {

  public project: Project = { id: "larabar-sustainability", name: "LÄRABAR Sustainability", version: "0.0.4" };
  public routes: Route[]= [];
  public photospheres: Photosphere[] = [];

  // Observable for dynamically loading photospheres
  public _fetchDataObservable: Observable<any> = new Observable<any>(undefined);

  // Subject that re-triggers the fetch data observable
  public fetchDataTrigger: BehaviorSubject<ProjectData|null> = new BehaviorSubject<ProjectData|null>(null);

  public dataSubject: BehaviorSubject<ProjectData|null> = new BehaviorSubject<ProjectData|null>(null);
  public rotation: BehaviorSubject<number> = new BehaviorSubject(0);  // .subscribe() to receive rotation updates
  public manualRotation: BehaviorSubject<number> = new BehaviorSubject(0);  // .next() to manually set sphere rotation
  public manualLat: BehaviorSubject<number> = new BehaviorSubject(0);
  public manualZoom: BehaviorSubject<number> = new BehaviorSubject(0);
  public rotateToHotspot: BehaviorSubject<Hotspot|null> = new BehaviorSubject<Hotspot|null>(null);
  public redrawHotspots: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hotspotX: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public hotspotY: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(null);
  public interactable = new BehaviorSubject(false);
  public currentRotation: BehaviorSubject<number> = new BehaviorSubject(0);
  public currentLat: BehaviorSubject<number> = new BehaviorSubject(0);
  public photosphereLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mainTextureId: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public updatePhotosphereImage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedHotspot: BehaviorSubject<Hotspot|null> = new BehaviorSubject<Hotspot|null>(null);
  public overlayPhotosphereUpdate: BehaviorSubject<PhotosphereUpdate|null> = new BehaviorSubject<PhotosphereUpdate|null>(null);
  public motionControls$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public motionControlsPossible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public motionControlsPermitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public motionControlsToggled$: BehaviorSubject<any> = new BehaviorSubject(null);

  // @blur @todo Enabling blur here `new BehaviorSubject<boolean>(true);` doesn't work!
  // Controls if motion blur is applied 
  public blurred: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Current active route path
   */
  public currentPath: string = "/";

  // Placeholder texture for new photopheres 8192x4096
  public psPlaceholderUrl: string = "/assets/images/photosphere-placeholder.png";
  public psPlaceholderBmp?: THREE.Texture;

  // Constructor
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.routesListener();
  }

  /**
   * Initialize scene data for routes, photospheres, and modals
   * @param data
   */
  public setData(data: ProjectData) {
    this.project = data.project;
    if (!this.project.urls) {
      this.project.urls = {};
    }
    this.routes = data?.routes ? data.routes : [];
    this.photospheres = data?.photospheres ? data.photospheres : [];
    this.dataSubject.next(data);
    this.setupRoutes();
  }

  /**
   * Build data from routes, photospheres
   */
  public getData(): ProjectData {
    const data: ProjectData = {
      project: this.project,
      routes: this.routes,
      photospheres: this.photospheres
    }
    return data;
  }

  /**
   * Get current data configuration as JSON string
   */
  public getDataAsJson(): string {
    return JSON.stringify(this.getData(), this.jsonReplacer, 4);
  }

  /**
   * Method for second parameter of JSON.stringify, i.e., a JSON replacer.
   * Excludes interal objects (like ThreeJS objects) from being serialized as JSON.
   * @param key
   * @param value
   * @returns
   */
   public jsonReplacer(key: any, value: any) {
    const excludeList: any[]= [
      // Photosphere model
      "mainImageBmp",
      "transparencyImageBmp",
      "overlayImgBmp",
      "clickableImgBmp",
      "backgroundImgBmp",
      // ImageData model
      "mesh",
      "mat",
      "geo",
      "created",
      // PhotosphereData model
      "texture",
      "geometry",
      "material",
      "tween",
      // Hotspost model
      "threeJs",
      "elem",
      "modal",
      "dragX",
      "dragY",
      "hsStartX",
      "hsStartY",
      "selected",
      "clickUrlExternal",
      "clickUrlInternal",
      // Used by multiple models
      "routePath",
      "matchedRoute",
    ];
    if (excludeList.includes(key)) {
      return undefined;
    } else {
      return value;
    }
  }

  /**
   * Set a photosphere image in a photosphere/scene.
   * A "photosphere image" is any image resource in a photosphere/scene that is rendered by ThreeJs, and requires a scene repaint.
   * @param url The URL to the updated image to load
   * @param photosphereId
   * @param updateProp Name of property in photosphere, e.g. "mainImage"
   * @param subProp Reserved for future use, not currently implemented; if we need to reference a property within another property
   */
  public setPhotosphereImage(url: string, photosphereId: any, updateProp: string, subProp: string = "") {
    if (this.photospheres) {
      const idx = this.photospheres.findIndex((ps: Photosphere) =>{
        return ps.id === photosphereId;
      });
      if (idx > -1 && this.photospheres[idx]) {
        if (updateProp === "mainImage" || updateProp === "overlayImage") {
          this.photospheres[idx][updateProp] = url;
          this.updatePhotosphereImage.next({
            url: url,
            photosphereId: photosphereId,
            updateProp: updateProp
          });
        } else {
          console.error(`Unrecognized Photosphere property '${updateProp}'`);
        }
      }
    }
  }

  /**
   * Add a hotpost to the current photosphere hotspots
   * @param hotspot
   */
  public addHotspot(hotspot: Hotspot) {
    if (this.currentPhotosphere?.value) {
      if (!this.currentPhotosphere.value.hotspots) {
        this.currentPhotosphere.value.hotspots = [];
      }
      this.currentPhotosphere.value.hotspots.push(
        hotspot
      );
      this.redrawHotspots.next(true);
    }
  }

  /**
   * @ps @routes Call this when changing this.routes
   * Dynamically create routes using current `this.routes` data
   * @param navigateToPathAtLoad Navigate to any path requested on document load, before Angular router navigated away from this path
   * @returns
   */
  public setupRoutes(navigateToPathAtLoad: boolean = true) {
    if (!this.routes) {
      return;
    }
    const config: any = [];
    for (let route of this.routes) {
      let path = route.path;
      if (path.startsWith("/")) {
        path = path.substring(1);
      }
      config.push({
        path: path,
        component: PhotosphereComponent
      })
    }
    this.router.resetConfig(config);
  }

  /**
   * Lookup / match the photosphere at a given route
   * @param path
   */
  public matchRoute(path: string): any {
    const route: Route|undefined = this.lookupRoute(path);
    if (route) {
      const photosphere = this.lookupPhotosphere(route.photosphereId);
      if (photosphere) {
        return {
          route: route,
          photosphere: photosphere
        }
      }
    }
    return null;
  }

  /**
   * Search current routes for route matching the given path
   * @param path
   * @returns
   */
  public lookupRoute(path: string): Route|undefined {
    return this.routes.find(r => path && r.path === path);
  }

  /**
   * Search current photospheres for the given photosphere, by id
   * @param id
   * @returns
   */
  public lookupPhotosphere(id: string): Photosphere|undefined {
    return this.photospheres.find((ps) => ps.id === id);
  }

  /**
   * Lookup a hotspot within a photosphere
   * @param photosphereId
   * @param hotspotId
   * @returns
   */
  public lookupHotspot(photosphereId: string|undefined|null, hotspotId: string): Hotspot|undefined {
    if (!photosphereId && this.currentPhotosphere?.value) {
      photosphereId = this.currentPhotosphere.value.id;
    }
    if (photosphereId) {
      const photosphere = this.lookupPhotosphere(photosphereId);
      if (photosphere && photosphere?.hotspots) {
        const hotspot = photosphere.hotspots.find((hs: Hotspot) => hs?.id === hotspotId );
        return hotspot;
      }
    }
    return undefined;
  }

  /**
   * @ps Set photosphere and/or modal from the given route path.
   * - Called at least one time, once the photosphere and route data has been loaded
   * @param path
   */
  public setPhotosphereFromRoute(path: any = null) {
    if (path === null) {
      path = this.currentPath;
    }
    const match = this.matchRoute(path);
    if (match) {
      this.setCurrentPhotosphere(
        match.photosphere.id,
        match.route?.hotspotId,
        match.route?.modalId
      );
    }
  }

  /**
   * Listener for route changes
   */
  public routesListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url.split('?')[0];
        const match = this.matchRoute(this.currentPath);
        if (match) {
          // Set the photosphere
          this.setCurrentPhotosphere(
            match.photosphere.id,
            match.route?.hotspotId,
            match.route?.modalId,
            {
              closePath: match.route?.closePath
            }
          );
        }
      }
    });
  }

  /**
   * Get JSON from specified URL
   * @returns
   */
  public getJson(url: string): Observable<any> {
    return this.http.get(url);
  }

  /**
   * Build a path / S3 key to project JSON file
   * @param projectId
   * @returns
   */
  public projectJsonPath(projectId: string, version: string = ""): string {
    if (version) {
      return `sites/json/${projectId}.${version}.json`;
    } else {
      return `sites/json/${projectId}.json`;
    }
  }

  /**
   * Get accessor for projectId
   * Reads from localStorage
   */
  get projectId(): string|null {
    return localStorage.getItem("hi-photosphere-project-id");
  }

  /**
   * Set accessor for projectId
   * Writes to localStorage
   */
  set projectId(id: string|null) {
    if (id === null || id === undefined) {
      id = "";
    }
    localStorage.setItem("hi-photosphere-project-id", id);
  }

  /**
   * Retrieve the project version number
   */
  get projectVersion(): string|null {
    if (this.project && this.project.version) {
      return this.project.version;
    } else {
      return localStorage.getItem("hi-photosphere-project-version");
    }
  }

  /**
   * Set the project version number
   */
  set projectVersion(version: string|null) {
    if (version === null || version === undefined) {
      version = "";
    }
    localStorage.setItem("hi-photosphere-project-version", version);
    if (this.project) {
      this.project.version = version;
    }
  }

  /**
   * Load and initialize project
   * @param projectId
   * @param jsonURL URL to load JSON from instead of the project JSON URL
   */
  public loadProject(projectId: string, jsonUrl: string): void {
    this.projectId = projectId;
    this._fetchDataObservable = this.getJson(jsonUrl);
  }

  /**
   * Controls if the photosphere can capture user interactions
   * @param val
   */
  public setInteractable(val: boolean): void {
    this.interactable.next(val);
  }

  /**
   * Set the current photosphere rotation
   * @param val
   */
  public setRotation(val: number): void {
    this.rotation.next(val);
  }

  /**
   * Define the current photosphere to display.
   * Optionally set the rotation.
   * - Sends photosphere data to `currentPhotosphere`
   * @param id
   * @param rotation
   * @returns
   */
  public setCurrentPhotosphere(id: string, hotspotId: string = "", modalId: string = "", modalArgs: any = null): void {
    // Locate hotspot
    const hotspot: Hotspot|undefined = hotspotId ? this.lookupHotspot(id, hotspotId) : undefined;
    // Don't re-set current photosphere
    // But do rotate to any defined hotspot and open any related modalId
    if (this.currentPhotosphere && this.currentPhotosphere.value) {
      if (id === this.currentPhotosphere.value.id) {
        if (hotspot && hotspot?.rotation) {
          //this.manualRotation.next(hotspot?.rotation);
          this.rotation.next(hotspot.rotation);
        }
        return;
      }
    }
    // Lookup updated photosphere data
    const data: Photosphere|undefined = this.lookupPhotosphere(id);
    if (data) {
      // Notify components
      this.currentPhotosphere.next(data);
      // Rotate to photosphere
      if (hotspot && hotspot?.rotation) {
        this.rotation.next(hotspot?.rotation);
      } else {
        this.rotation.next(data.rotationParams.startX ? data.rotationParams.startX : 180);
      }
    }
  }

  /**
   * Rotate photosphere to a hotspot
   * @param hotspotId
   */
   public setRotateToHotspot(hotspotId: string|undefined) {
    if (hotspotId) {
      const hotspot = this.lookupHotspot(null, hotspotId);
      if (hotspot) {
        this.rotateToHotspot.next(hotspot);
      }
    }
  }

  /**
   * Update opacity or other property of an overlay photosphere
   * @param update
   */
  public updateOverlayPhotosphere(update: PhotosphereUpdate) {
    this.overlayPhotosphereUpdate.next(update);
  }

  /**
   * Determine the route type from route definition
   * @param route
   * @returns
   */
  public routeType(route: Route): string {
    if (route.hotspotId) {
      return RouteType.Hotspot;
    } else {
      return RouteType.Photosphere;
    }
  }

  /**
   * Sort a list of routes by path.
   * @param routes
   */
  public routesSort(routes: Route[]) {
    routes.sort((a: Route, b: Route) => {
      if (b.path > a.path) {
        return -1;
      } else if (a.path > b.path) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
