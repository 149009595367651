import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import gsap from 'gsap';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-video-intro',
  templateUrl: './video-intro.component.html',
  styleUrls: ['./video-intro.component.scss']
})
export class VideoIntroComponent implements AfterViewInit {

  @Output() onVideoEnded = new EventEmitter();
  @ViewChild('video') _videoRef!: ElementRef;
  @ViewChild('cover') _coverRef!: ElementRef;
  @ViewChild('videoBg') _videoBgRef!: ElementRef;
  
  @ViewChild('lottie') _lottieAniRef!: ElementRef;
  // [options] = "aniOptions" 
  public showCover: boolean = false;
  public showVideo: boolean = false;

  public showBackground: boolean = false;

  public showInstructions: boolean = false;

  public animationDuration: number = 1000;
  public slowDownVideo: boolean = false;

  private videoPlayer!: HTMLVideoElement;
  private cover!: HTMLElement;
  private videoBg!: HTMLElement;
  private lottieContain!: HTMLElement;


  public flowerOptions: AnimationOptions = {
    path: "/assets/animations/Flower_Intro.json",
    loop: false,
    autoplay: false
  };

  public birdOptions: AnimationOptions = {
    path: "/assets/animations/Flower_Bird_Intro.json",
    loop: false,
    autoplay:false
  };
  public showAni:boolean = false;
  public aniOptions: AnimationOptions = (Math.random() > .5 ? this.birdOptions : this.flowerOptions);
  private lottieAni?: AnimationItem;


  constructor(private utilSvc: UtilsService) {
    // this.utilSvc.showHeader.next(false);
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    if (!this.videoPlayer) {
      this.videoPlayer = this._videoRef.nativeElement as HTMLVideoElement
    }

    if (!this.cover) {
      this.cover = this._coverRef.nativeElement as HTMLElement;
    }

    if (!this.videoBg) {
      this.videoBg = this._videoBgRef.nativeElement as HTMLElement;
    }

    if (!this.lottieContain) {
      this.lottieContain = this._lottieAniRef.nativeElement as HTMLElement;
      
    }
      console.log("WE HAVE A COVER. ");
      
      // gsap.delayedCall(1.5, this.playLottie.bind(this));
      gsap.to(this.cover, { backgroundColor: "#52bbc0", duration: 2, onComplete: this.playLottie.bind(this) });
    
  }

  playLottie() {
    console.log('PLAY LOTTIE.');
    this.showAni = true;
    this.showVideo= true;
    gsap.to(this.lottieContain, {duration:.1, opacity: 1});
    if (this.lottieAni) {
      this.lottieAni.play();
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.lottieAni = animationItem;
    console.log('animationCreated', this.lottieAni);
    if (this.lottieAni && this.showAni) {
      this.lottieAni.play();
    }
  }

  public videoEnded($event: any) {
    console.log("VIDEO IS COMPLETE");
    if (!this.utilSvc.isMobile.value)  {
      this.animateInVideoBG();
    }
    this.onVideoEnded.emit();
  }

  public videoTimeUpdate($event: any, video: any) {
    const millisecondsLeft = (video.duration * 1000) - (video.currentTime * 1000);
    if (millisecondsLeft <= this.animationDuration) {
      // this.onVideoEnded.emit();
      // this.slowVideo();
      // this.animateInVideoBG();
    }
  }

  public animateInVideoBG():void {
    // Might not be need to do this. The video endframe looks decent to me and the images dont line up. 
    this.showBackground = true
    gsap.to(this.videoBg, { duration: 1.5, opacity: 1 });
    gsap.to(this.videoBg, { duration: 3, delay: 1, ease: "power1.out", scale: 1, x: 0, y: 0 });
  }

  public slowVideo():void {
    if (!this.slowDownVideo) {
      console.log("We be slowing the video");
      this.slowDownVideo = true;
      let speed = { val: this.videoPlayer.playbackRate };
      gsap.to(speed, { duration: .5, val:.65, ease: "power1.out", onUpdate:this.slowVideoUpdate.bind(this), onUpdateParams:[speed] })
      
    }
  }

  public slowVideoUpdate(speed:any) {
    // adjust playback rate of video.. 
    this.videoPlayer.playbackRate = speed.val;
  }

  public videoDurationChange($event: any) {
    console.log($event);
    console.warn("videoDurationChange");
  }

  public videoLoadedMetaData($event: any, video: any) {
    console.log(video.duration);
    console.warn("videoLoadedMetaData");
  }

  public transitionToVideo() {
    gsap.to(this.lottieContain, { duration: .1, opacity: 0 });
    gsap.to(this.cover, { opacity:0, delay: 0, duration: .5});
    gsap.delayedCall(.4, this.transitionToVideoComplete.bind(this))
  }
  public transitionToVideoComplete() {
    console.log("PLAY THE VIDEO");
    this.videoPlayer.play();
  }


  public configReady() {

  }
  public domLoaded() {

  }
  public enterFrame($event: any) {
    // console.log($event);
    if (this.lottieAni) {
      if (this.lottieAni?.playDirection === 1) {
        // Lotties are a bit long. 
        if ($event.currentTime > 50) {
          if (this.utilSvc.showHeader.value !== true) {
            this.utilSvc.showHeader.next(true); 
          }
        }
        if ($event.currentTime > 80) {
          this.lottieAni.setDirection(-1);
          this.lottieAni.play();
          // this.utilSvc.showHeader.next(true);
        }
      }
    }
    
  }

  public loopComplete($event: any) {
    console.log('loop complete.', this.lottieAni, $event);

    if (this.lottieAni) {
      this.lottieAni.playDirection = -1;
    }
  }

  public complete($event: any) {
    console.log('Lottie is complete.');

    if (this.lottieAni) {
      if (this.lottieAni?.playDirection === 1) {
        // handled in enterFrame ^ 
        // this.lottieAni.setDirection(-1);
        // this.lottieAni.play();
        // this.utilSvc.showHeader.next(true);
      } else {
        gsap.set(this.lottieContain, { opacity: 0 });
        this.lottieAni.stop();
        this.transitionToVideo();
      }
    }


  }
  public destroy($event: any) {

  }
}
