<div class="inner-modal">
    <div class="start-container">
        <!-- <p class="start-title">WELCOME TO THE FARM</p> -->
        <p class="start-title">Welcome&nbsp;to Christine&nbsp;Gemperle’s Almond&nbsp;Orchard</p>
        <div class="video-box" [ngClass]="{'fullscreen': this.fullscreen}">
            <div class="vid-container">
                <video #videoPlayer [src]="videoURL" preload playsinline [poster]=""
                    [ngClass]="{'fullscreen': this.fullscreen}" (click)="this.pause()"></video>
                <div *ngIf="this.videoPlayer.paused" id="playBtn" class="play-button" (click)="this.play()">
                    <img src="../../../assets/images/play.svg">
                </div>
                <div (click)="this.toggleFullscreen()" id="fullscreenBtn" class="fullscreen-button">
                    <img src="../../../assets/images/fullscreen.svg">
                </div>
            </div>
        </div>
        <div class="start-button" (click)="this.close()">
            <p>START THE TOUR</p>
        </div>
    </div>
</div>